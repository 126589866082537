.navbar {
	z-index: $navbar-z;
}

.navbar-preload {
	position: absolute;
	width: 100%;
}

.main-nav-link {
	a {
		fa-icon {
			font-size: 1rem;
			vertical-align: middle;
		}
	}
}
