// 	Styles specific for Reports.

.submit-customer-container {
	font-family: $mainFont;
	font-weight: 500;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;
	min-height: calc(100vh - 105px);
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	// New style of tabs
	.nav-tabs {
		border-bottom: 2px solid $pkBlue;
		.nav-item {
			margin-bottom: 0;
			.nav-link {
				border: 0;
				font-weight: 600;
				&.active {
					color: $pkBlue;
					font-weight: 700;
					border-bottom: 2px solid $pkBlue;
				}
			}
		}
	}

	// Control Panel above table
	.table-controls {
		background-color: $offRowGray;
		border-bottom: 1px solid $darkGray;
		.table-control-link {
			padding: 0;
			a {
				display: block;
				padding: 0.75rem 15px;
				font-size: 0.85rem;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					i,
					fa-icon {
						color: $blueGray;
						font-size: 1rem;
						vertical-align: baseline;
					}
				}
				&:hover,
				&:focus {
					color: $white;
					background-color: $iconLinkBlue;
					i,
					fa-icon {
						color: $white;
					}
				}
				span {
					display: none;
				}
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.submit-customer-container {
		// Control Panel above table
		.table-controls {
			.table-control-link {
				a {
					span {
						display: inline;
					}
				}
			}
		}
	}
}
