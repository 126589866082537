.form-select select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

body > .dropdown {
	z-index: $dropdown-z !important;
}

.dropdown {
	a {
		i,
		fa-icon {
			font-size: 1rem;
		}
	}
}
