// 	Styles specific for the Upsell Banners on the Home Page

.banner-carousel {
	width: 320px;
	carousel {
		width: 320px;
		.carousel-control {
			display: none !important;
		}
		.carousel-inner {
			padding: 0;
			.animate-fade-in {
				animation: opacIn 0.8s;
			}
			@keyframes opacIn {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
			.carousel-item {
				border: 1px solid $pkBlue;
				.banner {
					width: 318px;
					min-height: 98px;
					padding: 8px 0 0 0;
					display: block;
					.main-headline {
						color: $white;
						font-weight: 700;
						font-size: 18px;
						text-transform: uppercase;
						margin: 0 0 0 10px;
					}
					.sub-headline {
						color: $white;
						font-weight: 500;
						font-size: 11px;
						margin: 0 60px 0 10px;
					}
					.banner-cta {
						margin: 0;
						width: 318px;
						height: 35px;
						position: absolute;
						top: 63px;
						.banner-btn {
							color: $white;
							font-size: 10px;
							font-weight: 700;
							text-transform: uppercase;
							position: absolute;
							top: 14px;
							right: 11px;
							&::after {
								content: 'X Close';
							}
						}
					}
					&.collapsed {
						.banner-cta {
							.banner-btn {
								&::after {
									content: 'Learn More';
								}
							}
						}
					}
				}
				.light-blue-banner {
					.banner {
						background: #12a5ff;
						background: -moz-linear-gradient(top, #12a5ff 0%, #0070b5 100%);
						background: -webkit-linear-gradient(top, #12a5ff 0%, #0070b5 100%);
						background: linear-gradient(to bottom, #12a5ff 0%, #0070b5 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12a5ff', endColorstr='#0070b5',GradientType=0 );
						.banner-cta {
							background-image: url('https://assets.powerkiosk.com/upsell-banner/banner-320-orangeCTA.png');
							background-repeat: no-repeat;
							.banner-btn {
								background-color: unset;
							}
						}
					}
					.collapse,
					.collapsing {
						background-color: #e47829;
						color: $white;
					}
				}
				.orange-banner {
					.banner {
						background: #fc8f3b;
						background: -moz-linear-gradient(top, #fc8f3b 0%, #cc6018 100%);
						background: -webkit-linear-gradient(top, #fc8f3b 0%, #cc6018 100%);
						background: linear-gradient(to bottom, #fc8f3b 0%, #cc6018 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc8f3b', endColorstr='#cc6018',GradientType=0 );
						.banner-cta {
							background-image: url('https://assets.powerkiosk.com/upsell-banner/banner-320-pkBlueCTA.png');
							background-repeat: no-repeat;
							.banner-btn {
								background-color: unset;
							}
						}
					}
					.collapse,
					.collapsing {
						background-color: $pkBlue;
						color: $white;
					}
				}
				.collapse,
				.collapsing {
					width: 318px;
					padding: 20px;
				}
			}
		}
	}
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
	.banner-carousel {
		width: 728px;
		carousel {
			width: 728px;
			.carousel-inner {
				.carousel-item {
					.banner {
						width: 726px;
						min-height: 88px;
						padding: 9px 0 0 0;
						.main-headline {
							font-size: 28px;
							margin: 0 0 0 18px;
						}
						.sub-headline {
							font-size: 12px;
							margin: 0 0 0 18px;
						}
						.banner-cta {
							width: 234px;
							height: 88px;
							top: 0px;
							right: 0px;
							.banner-btn {
								padding: 8px 28px;
								border-radius: 5px;
								top: 29px;
								right: 23px;
							}
						}
					}
					.light-blue-banner {
						.banner {
							.banner-cta {
								background-image: url('https://assets.powerkiosk.com/upsell-banner/banner-720-orangeCTA.png');
								background-repeat: no-repeat;
								.banner-btn {
									background-color: rgba(26, 37, 116, 0.5); // PK Blue with 50% opacity
								}
							}
							&.collapsed {
								.banner-cta {
									.banner-btn {
										background-color: $pkBlue;
									}
								}
							}
						}
						.collapse,
						.collapsing {
							background-color: #cc6018;
						}
					}
					.orange-banner {
						.banner {
							.banner-cta {
								background-image: url('https://assets.powerkiosk.com/upsell-banner/banner-720-pkBlueCTA.png');
								background-repeat: no-repeat;
								.banner-btn {
									background-color: rgba(18, 165, 255, 0.5); // Light Blue with 50% opacity
								}
							}
							&.collapsed {
								.banner-cta {
									.banner-btn {
										background-color: $lightBlue;
									}
								}
							}
						}
						.collapse,
						.collapsing {
							background-color: #0a0f37;
						}
					}
					.collapse,
					.collapsing {
						width: 726px;
						padding: 20px;
					}
				}
			}
		}
	}
}
