@mixin borderradius($radius: 5px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin boxshadow($hoffset, $voffset, $blurradius, $spreadradius, $color) {
	-webkit-box-shadow: $hoffset $voffset $blurradius $spreadradius $color;
	-moz-box-shadow: $hoffset $voffset $blurradius $spreadradius $color;
	box-shadow: $hoffset $voffset $blurradius $spreadradius $color;
}

body.login {
	background-color: #a9a9a9;

	.card {
		background-color: $white;
	}
}

input[type='file'].required:after {
	content: '';
}

#overlay {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #000;
	opacity: 0.7;
	filter: alpha(opacity=70);
	z-index: 10;
	display: block;
}

#loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -28px 0 0 -25px;
}

.sticky-footer {
	a {
		font-size: 0.75rem;
	}
}

.brand-card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	margin-bottom: 1.5rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border-radius: 0.25rem;
}
.brand-card-title {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 6rem;
	border-radius: 0.25rem 0.25rem 0 0;
}
.brand-card-title i {
	font-size: 2rem;
	color: #fff;
}
.brand-card-title .chart-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.brand-card-body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 0.75rem 0;
	text-align: center;
}
.brand-card-body > * {
	-ms-flex: 1;
	flex: 1;
	padding: 0.1875rem 0;
}
.brand-card-body > *:not(:last-child) {
	border-right: 1px solid #c8ced3;
}
*[dir='rtl'] .brand-card-body > *:not(:last-child) {
	border-right: 0;
	border-left: 1px solid #c8ced3;
}
.alert-activity-button {
	position: absolute;
	right: 0;
	top: -10px;
	height: 29px;
}
.brand-card-title {
	position: relative;
}
.brand-title-container {
	position: absolute;
	top: 20px;
}
.activity-container {
	position: relative;
}
.height-50 {
	height: 50px;
}
.badge-custom {
	letter-spacing: 1px;
	font-size: 0.8rem;
	font-weight: bold;
	min-width: 165px;
	margin-right: 40px;
}
.line-through {
	text-decoration: line-through !important;
}

html,
body {
	height: 100%;
}

main {
	min-height: -moz-calc(100% - 60px);
	min-height: -webkit-calc(100% - 60px);
	min-height: -o-calc(100% - 60px);
	min-height: calc(100% - 60px);
}

.delete {
	fa-icon {
		&:before {
			color: $danger;
		}
	}
	.fa-stack-2x {
		font-size: 2em !important;
	}
}

.email {
	i {
		font-size: 0.875rem;
	}
}

.btn-file {
	position: relative;
	overflow: hidden;
}
.btn-file input[type='file'] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	cursor: inherit;
	display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.no-capitalize {
	text-transform: lowercase !important;
}
