// 	Styles specific for Reports.

.commission-process-container {
	font-family: $mainFont;
	font-weight: 500;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;
	min-height: calc(100vh - 105px);
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	// New style of tabs
	.nav-tabs {
		border-bottom: 2px solid $pkBlue;
		.nav-item {
			margin-bottom: 0;
			.nav-link {
				border: 0;
				font-weight: 600;
				&.active {
					color: $pkBlue;
					font-weight: 700;
					border-bottom: 2px solid $pkBlue;
				}
			}
		}
	}

	// Control Panel above table
	.table-controls {
		background-color: $offRowGray;
		border-bottom: 1px solid $darkGray;
		.table-control-link {
			padding: 0;
			a {
				display: block;
				padding: 0.75rem 15px;
				font-size: 0.85rem;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					i,
					fa-icon {
						color: $blueGray;
						font-size: 1rem;
						vertical-align: baseline;
					}
				}
				&:hover,
				&:focus {
					color: $white;
					background-color: $iconLinkBlue;
					i,
					fa-icon {
						color: $white;
					}
				}
				span {
					display: none;
				}
			}
		}
	}

	// Added styles to the tables
	#pending-commissions-container {
		table {
			margin-bottom: 0;
			// Colored rows
			tr:not(.dx-freespace-row) {
				.dx-command-select {
					background-color: $blueGray !important;
					color: $white !important;
				}
				&.red-row {
					background-color: $faintPink;
					color: $red !important;
					font-weight: 600;
					.dark-cell,
					.dx-command-select {
						background-color: $red !important;
						color: $white !important;
					}
					a {
						&:link,
						&:visited,
						&:active {
							color: $red;
						}
						&:hover,
						&:focus {
							color: $black;
						}
					}
				}
				&.orange-row {
					background-color: $faintOrange !important;
					color: $orange !important;
					font-weight: 600;
					.dark-cell,
					.dx-command-select {
						background-color: $orange !important;
						color: $white !important;
					}
					a {
						&:link,
						&:visited,
						&:active {
							color: $orange;
						}
						&:hover,
						&:focus {
							color: $black;
						}
					}
				}
				&.green-row {
					background-color: $faintGreen !important;
					color: $green !important;
					.dark-cell,
					.dx-command-select {
						background-color: $green !important;
						color: $white !important;
					}
					a {
						&:link,
						&:visited,
						&:active {
							color: $green;
						}
						&:hover,
						&:focus {
							color: $black;
						}
					}
				}
				&.gray-row {
					background-color: $offRowGray !important;
					.dark-cell,
					.dx-command-select {
						background-color: $gray !important;
						color: $white !important;
					}
					a {
						&:link,
						&:visited,
						&:active {
							color: $darkGray;
						}
						&:hover,
						&:focus {
							color: $blue;
						}
					}
				}
				td {
					&.red-cell {
						background-color: $red;
					}
					&.orange-cell {
						background-color: $orange;
					}
					&.green-cell {
						background-color: $green;
					}
					&.off-row-gray-cell {
						background-color: $offRowGray;
					}
					&.off-white-cell {
						background-color: $offWhite;
					}
					.critical-status {
						color: $red;
						font-weight: 700;
					}
					.high-status {
						color: $orange;
						font-weight: 700;
					}
					.medium-status {
						color: $darkBlue;
						font-weight: 600;
					}
					.low-status {
						color: $blue;
						font-weight: 600;
					}
					.container-dropdown-row {
						max-width: 465px;
						padding: 1rem;
					}
				}
			}
			&.map-table {
				tr {
					th {
						&.drag-items {
							background-color: $lightGray;
							border-top: none;
							border-bottom: none;
							border-left: none;
							border-right: 2px solid $outlineGray;
						}
					}
					td {
						vertical-align: middle;
						min-width: 13.4rem;
						&.drag-items {
							background-color: $lightGray;
							border-top: none;
							border-bottom: none;
							border-left: none;
							border-right: 2px solid $outlineGray;
							.drag-item {
								background-color: $white;
								border: 1px solid $outlineGray;
								border-radius: 0.25rem;
								font-size: 0.75rem;
								padding: 0.5rem;
								min-height: 2.2rem;
								min-width: 11.8rem;
								i,
								fa-icon {
									color: $silver;
									float: right;
									font-size: 1rem;
								}
							}
						}
						&.drop-area {
							background-color: $blueGray;
							div {
								background-color: $white;
								border: 1px solid $outlineGray;
								border-radius: 0.25rem;
								font-size: 0.75rem;
								padding: 0.5rem;
								min-height: 2.2rem;
								min-width: 11.8rem;
								i,
								fa-icon {
									float: right;
									font-size: 1rem;
									vertical-align: unset;
									margin-right: 0;
								}
							}
						}
						&.empty-cell {
							background-color: $offRowGray;
						}
					}
				}
			}
		}
	}

	.bordered-div {
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		padding: 1rem;
	}

	// Commission Totals
	.comm-totals-container {
		.big-number-label {
			font-size: 0.75rem;
			font-weight: 600;
			margin-bottom: 0;
		}
		.big-number {
			font-size: 1.5rem;
			color: $pkBlue;
			font-weight: 700;
			line-height: 1.75rem;
			sup {
				font-size: 1rem;
			}
		}
		.card {
			border: none;
			padding: 0 1rem;
			.card-title {
				a {
					&:link,
					&:visited,
					&:active,
					&:focus {
						color: $pkBlue !important;
					}
					&:hover {
						color: $blue !important;
					}
					.comm-totals-left-side {
						.sub-text {
							display: block;
						}
					}
					.comm-totals-right-side {
						float: right;
					}
					.details-text {
						display: none;
					}
					i,
					fa-icon > svg {
						transition: 0.3s transform ease-in-out;
						margin-bottom: 0.1rem;
						vertical-align: bottom;
					}
					.fa {
						font-size: 1rem !important;
					}
					&.collapsed {
						&:link,
						&:visited,
						&:active,
						&:focus {
						}
						&:hover {
						}
						i,
						fa-icon > svg {
							transform: rotate(180deg);
						}
					}
				}
			}
			.collapse {
				.card-body {
					opacity: 1;
					animation: fade-out 0.15s 0s ease-out forwards;
				}
				&.show {
					.card-body {
						opacity: 0;
						animation: fade-in 0.15s 0s ease-out forwards;
					}
				}
			}
		}
	}
}

// Styles for the small breakpoint.
@media (min-width: 576px) {
}

// Styles for the medium breakpoint.
@media (min-width: 768px) {
	.commission-process-container {
		// Added styles to the tables
		#pending-commissions-container {
			table {
				// Colored rows
				tr:not(.dx-freespace-row) {
					td {
						.container-dropdown-row {
							max-width: 690px;
						}
					}
				}
			}
		}
		// Commission Totals
		.comm-totals-container {
			.card {
				.card-title {
					a {
						.details-text {
							opacity: 0;
							transition: opacity 1s;
							display: inline-block;
							font-size: 0.75rem;
							color: $iconLinkBlue !important;
							margin-right: 0.5rem;
						}
						&.collapsed {
							.details-text {
								opacity: 1;
								display: inline-block;
								font-size: 0.75rem;
								color: $iconLinkBlue !important;
								margin-right: 0.5rem;
							}
						}
					}
				}
			}
		}
	}
}

// Styles for the large breakpoint.
@media (min-width: 992px) {
	.commission-process-container {
		// Control Panel above table
		.table-controls {
			.table-control-link {
				a {
					span {
						display: inline;
					}
				}
			}
		}
		// Added styles to the tables
		#pending-commissions-container {
			table {
				// Colored rows
				tr:not(.dx-freespace-row) {
					td {
						.container-dropdown-row {
							max-width: 890px;
						}
					}
				}
			}
		}
	}
}

// Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.commission-process-container {
		// Added styles to the tables
		#pending-commissions-container {
			table {
				// Colored rows
				tr:not(.dx-freespace-row) {
					td {
						.container-dropdown-row {
							max-width: 1080px;
						}
					}
				}
			}
		}
	}
}
