.rate-matrix-loader {
	.bg-secondary {
		background-color: #6c757d !important;
	}
	.bg-danger {
		background-color: #ff0000 !important;
	}
	.bg-warning {
		background-color: #ff9e00 !important;
	}
	.bg-success {
		background-color: #23b000 !important;
	}
	.bg-primary {
		background-color: #001bff !important;
	}
}
