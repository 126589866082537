// 	Styles specific for the Supplier Home Page and Bid Page

.supplierAPI-container {
	/* Animations */
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;

	h1 {
		font-size: 1.75rem;
		font-weight: 700;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h2 {
		font-size: 1.25rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h3 {
		font-size: 1.15rem;
		font-weight: 600;
		color: $darkGray;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	p {
		line-height: 1.5rem;
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
		i,
		fa-icon {
			font-size: 1.25rem;
			vertical-align: text-bottom;
			margin-right: 0.25rem;
		}
		&.btn {
			&:link,
			&:visited,
			&:active {
				color: $white;
			}
			&:hover,
			&:focus {
				color: $white;
			}
			i,
			fa-icon {
				font-size: 1.25rem;
				vertical-align: sub;
				margin-right: 0.5rem;
			}
		}
	}

	.blue-line {
		border: 1px solid $pkBlue;
	}

	// Blue Row
	.blue-row {
		background-color: #1a2574;
		background-image: url('https://assets.powerkiosk.com/supplier-dashboard/api/code-bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		color: $white;
		.blue-row-width-limit {
			margin-left: auto;
			margin-right: auto;
			h2 {
				color: $white;
			}
		}
	}

	// Gray Row
	.gray-row {
		background: #f7f7f7; /* Old browsers */
		background: -moz-linear-gradient(top, #f7f7f7 0%, #d4d4d4 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #f7f7f7 0%, #d4d4d4 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #f7f7f7 0%, #d4d4d4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d4d4d4',GradientType=0 ); /* IE6-9 */
		.benefits-card {
			background-color: $white;
			border: 1px solid #a1a1a1;
			.sub-headline {
				color: $pkBlue;
				font-weight: 600;
			}
		}
	}

	.width-limit {
		margin-left: auto;
		margin-right: auto;
	}

	@for $i from 1 through 8 {
		.openAnim#{$i} {
			opacity: 0;
			animation: fade-in 0.5s ease-out #{$i * 0.25}s forwards;
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	.supplierAPI-container {
		h1 {
			font-size: 2em;
		}
		h2 {
			font-size: 1.75rem;
		}
		h3 {
			font-size: 1.25rem;
		}

		.blue-row-width-limit {
			max-width: 550px;
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.supplierAPI-container {
		h1 {
			font-size: 2.6rem;
		}
		h2 {
			font-size: 2.1rem;
		}
		h3 {
			font-size: 1.6rem;
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.supplierAPI-container {
		h1 {
			font-size: 2.75rem;
		}
		h2 {
			font-size: 2.25rem;
		}
		h3 {
			font-size: 1.75rem;
		}

		.width-limit {
			max-width: 1080px;
		}
	}
}
