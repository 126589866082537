// 	Styles specific for Reports.

.reports-container {
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h4 {
		font-family: $mainFont;
		font-weight: 600;
		color: $darkGray;
		font-size: 1rem;
		border-bottom: 1px solid $pkBlue;
		&.no-underline {
			border-bottom: none;
		}
		&.blue {
			color: $pkBlue;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}
	.big-number-label {
		font-size: 0.75rem;
		font-weight: 600;
		margin-bottom: 0;
	}
	.big-number {
		font-size: 1.75rem;
		color: $pkBlue;
		font-weight: 700;
		line-height: 1.75rem;
		sup {
			font-size: 1rem;
		}
		&.bigger-3 {
			font-size: 3rem;
			line-height: 3rem;
		}
	}

	// Report Form Container
	.reports-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			border-radius: 0 0 0.5rem 0.5rem;
			.main-form-row {
				padding: 1.5rem 1rem 2.5rem;
				.reports-switch-label {
					line-height: 1.5rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				&.no-button {
					padding: 1.5rem 1rem 0.5rem;
				}
				// Makes the label light blue when switch is checked
				.custom-control-input:checked + .reports-switch-label {
					color: $lightBlue;
					font-weight: 600;
				}
			}
			.shifted-button {
				position: relative;
				.btn {
					position: absolute;
					top: -1.15rem;
				}
			}
			.shifted-button-div {
				position: relative;
				.shifted-div {
					position: absolute;
					top: -1.15rem;
				}
			}
		}
	}

	.info-area {
		.gray-area {
			background-color: $offRowGray;
			border-radius: 0.5rem;
		}
		.border-on-left {
			border-top: 1px solid $darkGray;
			border-left: none;
			padding-top: 1rem;
		}
		.border-on-right {
			border-bottom: 1px solid $darkGray;
			border-right: none;
			padding-bottom: 1rem;
		}
		.big-number {
			font-size: 1.5rem;
			font-weight: 700;
			color: $pkBlue;
			&.red-text {
				color: $red;
			}
		}
	}

	.view-selector {
		.active {
			font-weight: 600;
			color: $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.left-link {
			border-right: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.right-link {
			border-left: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
		.link:not(:first-child) {
			border-left: 1px solid $pkBlue;
			padding: 0.25rem 0.35rem;
		}
	}

	.download-link {
		font-weight: 600;
		i,
		fa-icon {
			font-size: 1.25rem;
			vertical-align: middle;
			margin-right: 0.35rem;
		}
	}

	// Circle Filter Links
	.circle-filters {
		a {
			&:link,
			&:visited,
			&:active {
				color: $placeholderGray;
				font-weight: 500;
				.circle-shape {
					border: 1px solid $outlineGray;
					border-radius: 50%;
					width: 1.5rem;
					height: 1.5rem;
					display: inline-block;
					margin-right: 0.35rem;
					vertical-align: bottom;
				}
			}
			&:hover,
			&:focus {
				color: $black;
				.circle-shape {
					border: 1px solid $blue;
				}
			}
			&.active-filter {
				&:link,
				&:visited,
				&:active {
					color: $black;
					font-weight: 600;
					.circle-shape {
						border: 1px solid $darkGray;
					}
				}
				&:hover,
				&:focus {
					color: $black;
					.circle-shape {
						border: 1px solid $pkBlue;
					}
				}
			}
		}
		.table-control-link {
			a {
				font-size: 0.85rem;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					color: $lightBlue;
					i,
					fa-icon {
						color: $blueGray;
						font-size: 1rem;
						vertical-align: text-bottom;
					}
				}
				&:hover,
				&:focus {
					color: $blue;
					i,
					fa-icon {
						color: $blue;
					}
				}
			}
		}
	}

	// Control Panel above table
	.table-controls {
		background-color: $offRowGray;
		border-bottom: 1px solid $darkGray;
		.table-control-link {
			padding: 0;
			a {
				display: block;
				padding: 0.75rem 15px;
				font-size: 0.85rem;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					i,
					fa-icon {
						color: $blueGray;
						font-size: 1rem;
						vertical-align: baseline;
					}
				}
				&:hover,
				&:focus {
					color: $white;
					background-color: $iconLinkBlue;
					i,
					fa-icon {
						color: $white;
					}
				}
				span {
					display: none;
				}
				&.disabled {
					&:link,
					&:visited,
					&:active,
					&:hover,
					&:focus {
						color: $outlineGray;
						background-color: $offRowGray;
						cursor: not-allowed;
						i,
						fa-icon {
							color: $outlineGray;
						}
					}
				}
			}
		}
	}

	.dx-datagrid,
	.table-container tbody {
		td {
			.commodity-icons {
				text-align: center;
				white-space: nowrap;
				img {
					width: auto;
					height: 2rem;
					margin: 0 0.25rem;
				}
			}
			.chart-cell {
				p {
					white-space: pre-wrap;
				}
			}
		}
	}

	.table-container {
		overflow-x: auto;
		.table-striped {
			border: 1px solid $outlineGray;
			thead {
				tr {
					th {
						border-top: 1px solid $outlineGray;
						border-right: 1px solid $outlineGray;
						border-left: 1px solid $outlineGray;
						border-bottom: 2px solid $outlineGray;
						padding: 1rem;
					}
				}
			}
			tbody {
				tr {
					th {
						font-weight: 600;
						border: 1px solid $outlineGray;
						padding: 1rem;
					}
					td {
						border: 1px solid $outlineGray;
						padding: 1rem;
						p {
							strong,
							b {
								font-weight: 600;
							}
						}
						&.red-status {
							i,
							fa-icon {
								color: $red;
							}
							&.full-color {
								font-weight: 600;
								color: $red;
							}
						}
						&.orange-status {
							i,
							fa-icon {
								color: $orange;
							}
							&.full-color {
								font-weight: 600;
								color: $orange;
							}
						}
						&.green-status {
							i,
							fa-icon {
								color: $green;
							}
							&.full-color {
								font-weight: 600;
								color: $green;
							}
						}
						&.dark-blue-status {
							i,
							fa-icon {
								color: $darkBlue;
							}
						}
						&.blue-gray-status {
							i,
							fa-icon {
								color: $blueGray;
							}
							&.full-color {
								font-weight: 600;
								color: $blueGray;
							}
						}
						&.light-blue-status {
							i,
							fa-icon {
								color: $lightBlue;
							}
							&.full-color {
								font-weight: 600;
								color: $lightBlue;
							}
						}
					}
					&:nth-of-type(odd) {
						background-color: $white;
					}
					&:nth-of-type(even) {
						background-color: $offRowGray;
					}
					&.total-row {
						background-color: $placeholderGray;
						color: $white;
						font-weight: 600;
						font-size: 1.1rem;
					}
					&.vert-align-middle {
						td {
							vertical-align: middle !important;
						}
					}
					&.red-row {
						background-color: $faintPink;
						color: $red;
						font-weight: 600;
					}
					&.orange-row {
						background-color: $faintOrange;
						color: $orange;
						font-weight: 600;
					}
					&.link-row:hover {
						cursor: pointer;
					}
				}
			}
			&.reduce-cell-padding {
				thead {
					tr {
						th {
							padding: 0.75rem;
						}
					}
				}
				tbody {
					tr {
						th {
							padding: 0.75rem;
						}
						td {
							padding: 0.75rem;
						}
					}
				}
			}
		}
	}

	.cards-container {
		.supplier-card {
			border: 1px solid $placeholderGray;
			border-radius: 0.5rem;
			img {
				width: auto;
				height: 2rem;
				margin: 0 0.5rem 0 0;
			}
			.card-body {
				padding-bottom: 0;
			}
		}
	}

	.leadlink-package {
		background-color: $white;
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		box-shadow: none;
		padding-top: 1rem;
		margin: 0.5rem;
		transition: 0.25s ease-in-out;
		min-height: 23rem;
		h4 {
			color: $pkBlue;
			font-size: 1.15rem;
			margin-bottom: 0;
		}
		.product-image {
			width: 100%;
			height: auto;
			max-width: 6.25rem;
		}
		.price-div {
			background-color: $offRowGray;
			margin-top: 1rem;
			padding-top: 1rem;
			border-radius: 0rem 0rem 0.5rem 0.5rem;
			.slash-price {
				margin-bottom: -0.75rem;
			}
			.display-price {
				color: $darkBlue;
				font-weight: 700;
				font-size: 2.75rem;
				margin-bottom: -0.25rem;
			}
		}
		&:hover,
		&:focus {
			background-color: $offWhite;
			border: 1px solid $darkBlue;
			border-radius: 0.5rem;
			box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.25);
			cursor: pointer;
			transition: 0.25s ease-in-out;
			.price-div {
				background-color: $halfBlue;
				border-radius: 0rem 0rem 0.5rem 0.5rem;
				.btn-secondary {
					background-color: $blue !important;
				}
			}
		}
		&.disabled {
			background-color: $offWhite;
			border: 1px solid $gray;
			box-shadow: none;
			color: $placeholderGray;
			h4 {
				color: $placeholderGray;
			}
			.product-image {
				opacity: 0.5;
			}
			.price-div {
				background-color: $lightGray;
				position: absolute;
				bottom: 0;
			}
			&:hover,
			&:focus {
				background-color: $offWhite;
				border: 1px solid $gray;
				cursor: not-allowed;
				.price-div {
					background-color: $lightGray;
				}
			}
		}
	}

	.referral-card {
		background-color: $white;
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		box-shadow: none;
		padding-top: 1rem;
		margin: 0.5rem;
		transition: 0.25s ease-in-out;
		min-height: 23rem;
		h4 {
			color: $pkBlue;
			font-size: 1.15rem;
			border-bottom: 0;
		}
		.category-image {
			width: 100%;
			height: auto;
			max-width: 6.25rem;
		}
		&:hover,
		&:focus {
			background-color: $offWhite;
			border: 1px solid $darkBlue;
			border-radius: 0.5rem;
			box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.25);
			cursor: pointer;
			transition: 0.25s ease-in-out;
		}
		&.disabled {
			background-color: $offWhite;
			border: 1px solid $gray;
			box-shadow: none;
			color: $placeholderGray;
			h4 {
				color: $placeholderGray;
			}
			.category-image {
				opacity: 0.5;
			}
			&:hover,
			&:focus {
				background-color: $offWhite;
				border: 1px solid $gray;
				cursor: not-allowed;
			}
		}
		&.listing {
			min-height: 20rem;
			.category-image {
				width: 100%;
				height: auto;
				max-width: 10.5rem;
			}
			.request-row {
				position: absolute;
				bottom: 0;
			}
			&:hover,
			&:focus {
				background-color: $white;
				border: 1px solid $outlineGray;
				border-radius: 0.5rem;
				box-shadow: unset;
				cursor: default;
				transition: none;
			}
		}
	}

	.ref-listing-icon {
		width: 100%;
		height: auto;
		max-width: 4.5rem;
	}

	.countdown-banner {
		background-color: $offWhite;
		border: 2px solid $darkBlue;
		border-radius: 0.5rem;
	}
	.countdown-banner-left {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.countdown-banner-right {
		color: $white;
		background-color: $darkBlue;
		border: 2px solid $darkBlue;
		border-radius: 0 0 0.25rem 0.25rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		min-width: 10rem;
	}

	// Small Energy Icon
	.sm-energy-icon {
		height: 1.5rem;
		width: auto;
	}

	// CRM Status Indicators
	.crm-status-item {
		position: relative;
		.status-indicator {
			position: absolute;
			top: 0;
			bottom: 0.65rem;
			left: 1.5rem;
			width: 0.3rem;
			border-radius: 0.2rem;
			&.yellow-status {
				background-color: $yellow;
			}
			&.pkBlue-status {
				background-color: $pkBlue;
			}
			&.orange-status {
				background-color: $orange;
			}
			&.green-status {
				background-color: $green;
			}
			&.ltBlue-status {
				background-color: $lightBlue;
			}
			&.blue-status {
				background-color: $blue;
			}
			&.gray-status {
				background-color: $gray;
			}
			&.placeholder-gray-status {
				background-color: $placeholderGray;
			}
		}
		p {
			margin-left: 1.5rem;
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.reports-container {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.25rem;
		}

		.info-area {
			.border-on-left {
				border-top: none;
				border-left: 1px solid $darkGray;
				padding-top: 0;
			}
			.border-on-right {
				border-bottom: none;
				border-right: 1px solid $darkGray;
				padding-bottom: 0;
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.reports-container {
		// Control Panel above table
		.table-controls {
			.table-control-link {
				a {
					span {
						display: inline;
					}
				}
			}
		}
	}
}
