//  Styles specific for the News & Resources Section

/* Animations */
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.news-container {
	overflow: auto;
	font-family: $mainFont;
	font-size: 1rem;
	color: #070707;
	&.no-side-padding {
		padding: 2rem 0 0;
	}
	&.cover-image {
		background-position: top left;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		min-height: calc(100vh - 105px);
	}
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
		&.big-title {
			font-size: 2rem;
		}
	}
	h2 {
		font-size: 1.25rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h4 {
		font-size: 0.85rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	p {
		line-height: 1.75rem;
	}

	.add-text-shadow {
		text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
	}

	.white-bg {
		background-color: $white;
	}

	.blurb {
		margin-bottom: 0;
	}

	.publish-date {
		color: $placeholderGray;
	}

	.big-quote {
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 2.5rem;
		padding: 1rem 0;
	}

	.article-cta {
		background-color: $offRowGray;
	}

	.article-accordian {
		.card {
			border: none;
			.card-header {
				background-color: unset;
				padding: 0;
				border-bottom: none;
				.full-link {
					padding: 0.75rem 1.25rem;
					display: block;
					border-bottom: 1px solid $pkBlue;
					transition: all 0.15s ease;
					&:link,
					&:visited,
					&:active {
						background-color: $white;
					}
					&:hover,
					&:focus {
						background-color: $offRowGray;
						border-bottom: 1px solid $darkGray;
					}
					&.collapsed {
						border-bottom: 2px solid $gray;
						&:link,
						&:visited,
						&:active {
							background-color: $white;
						}
						&:hover,
						&:focus {
							background-color: $offRowGray;
							border-bottom: 2px solid $darkGray;
						}
					}
				}
			}
			&:last-child {
				.card-header {
					.full-link {
						&.collapsed {
							border-bottom: none;
							&:hover,
							&:focus {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
	}

	.speaker-thumbnail {
		margin-top: -8.5rem;
		.speaker-name {
			background-color: $darkBlue;
			padding: 8rem 0 1rem 0;
			color: #ffffff;
			border-radius: 0.5rem;
		}
		.speaker-image {
			position: relative;
			top: 8.5rem;
		}
	}

	// Styles to make a table look like an infographic
	.infographic-table {
		font-weight: 600;
		thead {
			th {
				border-bottom: unset;
			}
		}
		th,
		td {
			border-top: unset;
			vertical-align: middle;
			i,
			fa-icon {
				vertical-align: bottom;
				font-size: 1.5rem;
				position: relative;
				top: 6px;
			}
		}
		td {
			padding: 1.5rem 1rem;
		}
		.dark-blue-row {
			background-color: $pkBlue;
			color: $white;
			.financial-profile {
				font-size: 0.75rem;
				font-weight: 700;
				text-transform: uppercase;
				i,
				fa-icon {
					font-size: 2rem;
					vertical-align: unset;
				}
				// Frequent Broker Colors
				&.diamond-style {
					color: $diamond;
				}
				&.platinum-style {
					color: $white;
				}
				&.gold-style {
					color: $yellow;
				}
				&.silver-style {
					color: $silver;
				}
				&.full-style {
					color: $lightBlue;
				}
			}
		}
		.light-green-row {
			background-color: $lightGreen;
			color: $green;
		}
		.pink-row {
			background-color: $faintPink;
			color: $red;
		}
		.half-blue-row {
			background-color: $halfBlue;
			color: $darkBlue;
		}
		.light-gray-row {
			background-color: $offRowGray;
			.add-bottom-border {
				border-bottom: 1px solid $outlineGray;
			}
		}
	}

	// Big Featured Item on Main Page
	.big-featured-item {
		.featured-date {
			font-size: 0.85rem;
			margin-bottom: 0;
		}
		h3 {
			font-size: 1.5rem;
			line-height: 1.5rem;
		}
		.featured-content {
			font-size: 1rem;
		}
	}

	// News items on listings pages
	.card {
		&.news-listing-item {
			border: none;
			border-radius: 0;
			.card-body {
				padding: 0.5rem 0;
				.card-title {
					font-size: 1rem;
					line-height: 1.25rem;
					margin-bottom: 0;
				}
				.card-date {
					font-size: 0.75rem;
					line-height: 0.95rem;
					margin-bottom: 0.85rem;
				}
				.card-text {
					font-size: 0.85rem;
					line-height: 1.25rem;
				}
			}
		}
	}
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {
	.news-container {
		.big-quote {
			font-size: 2rem;
			padding: 1.5rem 0;
		}
	}
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
	.news-container {
		&.no-side-padding {
			padding: 2rem;
		}
		h1 {
			font-size: 2rem;
			&.big-title {
				font-size: 3.5rem;
			}
		}
		h2 {
			font-size: 1.5rem;
			line-height: 2rem;
		}
		h3 {
			font-size: 1.25rem;
			line-height: 1.5rem;
		}
		h4 {
			font-size: 1rem;
			line-height: 1.25rem;
		}
		.blurb {
			&.blurb-bigger {
				font-size: 1.25rem;
				line-height: 1.75rem;
			}
		}
		.big-quote {
			font-size: 2.5rem;
			padding: 2rem;
			line-height: 3rem;
		}

		// Big Featured Item on Main Page
		.big-featured-item {
			h3 {
				font-size: 2rem;
				line-height: 2rem;
			}
		}
	}
}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.news-container {
		// Big Featured Item on Main Page
		.big-featured-item {
			h3 {
				font-size: 3rem;
				line-height: 3rem;
			}
		}
	}
}

// Animation to gradually bring items in
@for $i from 1 through 20 {
	.openAnim#{$i} {
		opacity: 0;
		animation: fade-in 0.5s ease-out #{$i * 0.25}s forwards;
	}
}
