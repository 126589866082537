// 	Styles specific for the Rate Grid not including form fields.

.instant-quote-container {
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fade-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding-left: 2rem !important;
	padding-right: 2rem !important;
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue !important;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	.tooltip-span {
		color: inherit;
		cursor: pointer;
	}

	// Big numbers for the upload section
	.big-number {
		font-weight: 700;
		font-size: 3rem;
		color: $pkBlue;
		line-height: 2.5rem;
	}

	// Style for address that isn't selected
	.address-not-selected {
		color: $outlineGray;
	}

	// Commission Plan Selection Layouts on Payment Page
	.commission-plan-options {
		.commission-plan-row {
			background-color: $offRowGray;
			border-left: 4px solid $blue;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			margin-bottom: 1rem;
			.checkbox-sim {
				i,
				fa-icon {
					color: $darkGray;
					font-size: 2rem;
					background-color: $offRowGray;
					padding: 0;
					margin: 0.25rem 0 0 0;
					line-height: 1.5rem;
					svg {
						background-color: $offRowGray;
						path {
							background-color: $white;
						}
					}
				}
			}
			h3 {
				border-bottom: 0;
				font-size: 1.15rem;
				margin-bottom: 0;
			}
			.small-label {
				font-size: 0.8rem;
				margin-bottom: 0;
				line-height: 0.7rem;
			}
			.big-number {
				font-size: 1.5rem;
				margin-bottom: 0;
				line-height: 2rem;
			}
			&:hover {
				cursor: pointer;
				background-color: $halfBlue;
				fa-icon {
					background-color: $halfBlue;
				}
			}
			&.active {
				background-color: $gray;
				border-left: 4px solid $pkBlue;
				.checkbox-sim {
					i,
					fa-icon {
						color: $pkBlue;
						background-color: $gray;
					}
				}
				h3 {
					border-bottom: 0;
					font-size: 1.25rem;
				}
				.small-label {
					font-size: 0.8rem;
					line-height: 0.7rem;
				}
				.big-number {
					font-size: 1.5rem;
					margin-bottom: 0;
					line-height: 2rem;
				}
				&:hover {
					cursor: pointer;
					background-color: $halfBlue;
				}
			}
			&.invalid {
				border-left: 4px solid $red;
				color: $darkGray !important;
				.checkbox-sim {
					i,
					fa-icon {
						color: $red;
					}
				}
				h3 {
					color: $red;
				}
				.small-label {
					font-size: 0.8rem;
					line-height: 0.7rem;
				}
				.big-number {
					color: $red;
				}
			}
		}
	}

	// Instant Quote Container
	.instant-quote-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $gray;
			border-right: 1px solid $gray;
			border-bottom: 1px solid $gray;
			border-radius: 0 0 1rem 1rem;
			.form-row-01 {
				padding: 1rem 0 0.25rem;
			}
			.form-row-02 {
				padding: 0.25rem 0;
			}
			.form-row-03 {
				padding: 0.25rem 0 3rem;
			}
			.form-row-04 {
				.shifted-button {
					position: relative;
					.btn {
						position: absolute;
						top: -1.15rem;
					}
				}
				.shifted-button-div {
					position: relative;
					.shifted-div {
						position: absolute;
						top: -1.15rem;
					}
				}
			}
		}
	}

	// Rate Bar
	.rate-bar-container {
		padding: 0 1rem;
		margin-bottom: 2rem;
		.card {
			background-color: $lightGray !important;
			border-radius: 0.5rem;
			.card-title {
				font-size: 1rem;
				background-color: none !important;
				border-bottom: none;
				padding: 0;
				a {
					border-radius: 0.5rem;
					padding: 0.75rem 1.25rem;
					border: 1px solid;
					&:link,
					&:visited,
					&:active,
					&:focus {
						background-color: $lightGray !important;
						color: $pkBlue !important;
						border-color: rgba(0, 0, 0, 0);
					}
					&:hover {
						background-color: $lightGray !important;
						color: $pkBlue !important;
						border-color: rgba(0, 0, 0, 0);
					}
					.rate-bar-left-side {
						.sub-text {
							font-size: 0.8rem;
							font-weight: 500;
							color: $darkGray;
							display: block;
							padding-top: 0.2rem;
						}
					}
					.rate-bar-right-side {
						float: right;
						padding-top: 0.4rem;
					}
					.details-text {
						display: none;
					}
					i,
					fa-icon > svg {
						transition: 0.3s transform ease-in-out;
						color: $iconLinkBlue !important;
						vertical-align: bottom;
					}
					.fa {
						font-size: 1rem !important;
					}
					&.collapsed {
						&:link,
						&:visited,
						&:active,
						&:focus {
							background-color: $lightGray !important;
							color: $pkBlue !important;
							border-color: rgba(0, 0, 0, 0);
							transition: 0.3s border-color;
						}
						&:hover {
							background-color: $lightGray !important;
							color: $pkBlue !important;
							// border-color: $placeholderGray;
						}
						i,
						fa-icon > svg {
							transform: rotate(180deg);
							color: $iconLinkBlue !important;
						}
					}
				}
			}
			.collapse {
				.card-body {
					opacity: 1;
					animation: fade-out 0.15s 0s ease-out forwards;
				}
				&.show {
					.card-body {
						opacity: 0;
						animation: fade-in 0.15s 0s ease-out forwards;
						font-size: 0.8rem;
						h3 {
							font-size: 0.85rem;
							padding-bottom: 0;
							border-bottom: none;
						}
						.rate-bar {
							position: relative;
							.color-bar {
								padding: 0 1rem 3rem;
								.left-part {
									border-radius: 0.25rem 0 0 0.25rem;
									background-color: $green !important;
									height: 6px;
								}
								.middle-part {
									background-color: $yellow !important;
									height: 6px;
								}
								.right-part {
									border-radius: 0 0.25rem 0.25rem 0;
									background-color: $red !important;
									height: 6px;
								}
							}
							.lower-numbers {
								p {
									font-size: 0.75rem;
									margin-bottom: 0.25rem;
								}
							}
							.pointer {
								position: absolute;
								top: -8px;
								.rate-bar-tooltip {
									width: 170px;
									position: relative;
									top: 50px;
									left: 0;
									background-color: $blueGray !important;
									color: $white !important;
									font-size: 0.75rem;
									font-weight: 600;
									padding: 0.75rem;
									border-bottom-left-radius: 0.2rem;
									border-bottom-right-radius: 0.2rem;
									border-top-right-radius: 0.2rem;
									&:after,
									&:before {
										right: 46.75%;
										top: -33%;
										border: solid transparent;
										content: ' ';
										height: 0;
										width: 0;
										position: absolute;
										pointer-events: none;
										transform: rotate(90deg);
									}
									&:after {
										border-right-color: $blueGray !important;
										border-width: 6px;
										margin-top: 2px;
									}
									&:before {
										border-right-color: $blueGray !important;
										border-width: 6px;
										margin-top: 2px;
									}
									fa-icon {
										position: absolute;
										left: 42.5%;
										top: -29px;
										color: $white;
										transform: scale(0.5);
										&.rate-bar-indicator {
											color: $blueGray;
											transform: scale(0.8);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Sub-headline showing Contract Load Factor
	.contract-load-factor-header {
		font-size: 0.85rem;
		color: $darkGray;
		font-weight: 600;
		border-bottom: 1px solid $pkBlue;
		margin-bottom: 0.5rem;
		padding-bottom: 0.25rem;
	}

	// Links below the Rate Bar
	.rate-grid-link {
		font-weight: 600;
		a {
			img {
				height: 36px;
				width: auto;
				margin-bottom: 0.5rem;
				margin-top: 0.5rem;
			}
		}
	}
	.icons-link {
		font-weight: 600;
		a {
			i,
			fa-icon {
				font-size: 36px;
				color: $iconLinkBlue;
				margin-bottom: 0.5rem;
			}
		}
	}
	.email-plans-link {
		font-weight: 600;
		a {
			i,
			fa-icon {
				font-size: 36px;
				color: $iconLinkBlue !important;
				vertical-align: middle;
				margin-right: 0.5rem;
			}
		}
	}

	// List of Best Plans
	.best-plans-header {
		margin-bottom: 1rem;
	}

	// List of Available Plans
	.other-plans-header {
		margin-top: 2rem;
		margin-bottom: 1rem;
		.display-numbers {
			color: $darkGray;
			font-size: 0.75rem;
		}
		.terms-filters {
			.most-popular-terms {
				font-size: 0.75rem;
				display: inline-block;
				border-right: 1px solid $pkBlue;
				height: 2rem;
				padding: 0.35rem 0.75rem 0rem 0;
			}
			.all-terms {
				font-size: 0.75rem;
				display: inline-block;
				height: 2rem;
				padding: 0.35rem 0 0 0.5rem;
			}
		}
		.sort-filters {
			pk-broker-form-dropdown {
				width: 100%;
				label {
					margin: 0 0.5rem;
				}
				.pseudo-form-control {
					display: inline-block;
					width: 80%;
					a {
						font-size: 0.75rem;
					}
				}
			}
		}
	}

	// Styles for the plan cards
	.best-plans-list,
	.other-plans-list {
		margin: 0;
		.plan-card {
			background-color: $white;
			border: 1px solid $blueGray;
			border-radius: 0.5rem;
			margin-bottom: 1rem;
			.logo {
				min-height: 70px;
				img {
					width: 7rem;
					height: auto;
				}
			}
			.base-rate {
				color: $pkBlue;
				font-size: 1.85rem;
				font-weight: 600;
				line-height: 1rem;
				margin-bottom: 0;
				position: relative;
				z-index: 2;
				&.base-rate-smaller {
					font-size: 1.65rem;
				}
			}
			.small-title {
				font-size: 0.75rem;
				font-weight: 600;
				line-height: 0.75rem;
				margin-bottom: 0;
			}
			.more-details-link-row {
				.more-details-link {
					font-weight: 600;
					&.collapsed {
						&::before {
							content: 'More Details ';
						}
						i,
						fa-icon > svg {
							transform: rotate(180deg);
						}
					}
					&::before {
						content: 'Close Details ';
					}
					i,
					fa-icon > svg {
						color: $pkBlue;
						transition: 0.3s transform ease-in-out;
					}
				}
			}
			.more-details-collapse {
				background-color: $offRowGray;
			}
			.more-options-link-row {
				.more-options-link {
					font-weight: 600;
					&.collapsed {
						&::before {
							content: 'More Options ';
						}
						i,
						fa-icon > svg {
							transform: rotate(180deg);
						}
					}
					&::before {
						content: 'Close Options ';
					}
					i,
					fa-icon > svg {
						color: $pkBlue;
						transition: 0.3s transform ease-in-out;
					}
				}
			}

			// Special Ribbons
			.ribbon {
				width: 115px;
				height: 115px;
				overflow: hidden;
				position: absolute;
				z-index: 1;
				&::before,
				&::after {
					position: absolute;
					z-index: -1;
					content: '';
					display: block;
				}
				span {
					position: absolute;
					display: block;
					width: 225px;
					padding: 10px 0;
					box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
					color: $white;
					font-weight: 700;
					font-size: 0.75rem;
					text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
					text-transform: uppercase;
					text-align: center;
				}
				&.best-overall {
					&::before,
					&::after {
						border: 5px solid #16345e;
					}
					span {
						background-color: $darkBlue;
					}
				}
				&.best-plan {
					&::before,
					&::after {
						border: 5px solid #557384;
					}
					span {
						background-color: $blueGray;
					}
				}
			}
			.ribbon-top-left {
				top: -10px;
				left: -10px;
				&::before {
					border-top-color: transparent;
					border-left-color: transparent;
					top: 0;
					right: 0;
				}
				&::after {
					border-top-color: transparent;
					border-left-color: transparent;
					bottom: 0;
					left: 0;
				}
				span {
					right: -38px;
					top: 13px;
					transform: rotate(-45deg);
					line-height: 1rem;
				}
			}
			// Disabled State
			&.disabled {
				background-color: $offWhite;
				border: 1px solid $gray;
				.blue-text {
					color: $blueGray !important;
				}
				img {
					opacity: 0.5;
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.instant-quote-container {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.25rem;
		}

		// Rate Bar
		.rate-bar-container {
			.card {
				.card-title {
					a {
						.details-text {
							opacity: 0;
							transition: opacity 1s;
							display: inline-block;
							font-size: 0.75rem;
							color: $iconLinkBlue !important;
							margin-right: 0.5rem;
						}
						&.collapsed {
							.details-text {
								opacity: 1;
								display: inline-block;
								font-size: 0.75rem;
								color: $iconLinkBlue !important;
								margin-right: 0.5rem;
							}
						}
					}
				}
			}
			.collapse {
				&.show {
					.card-body {
						.rate-bar {
							.color-bar {
								padding: 3rem 1rem 0 0;
							}
						}
					}
				}
			}
		}

		// Styles for the plan cards
		.best-plans-list,
		.other-plans-list {
			.plan-card {
				.more-details-collapse {
					border-radius: 0 0 0.5rem 0.5rem;
				}
				&.customer-card {
					.more-details-collapse {
						border-radius: 0;
					}
				}
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.instant-quote-container {
		// List of Available Plans
		.other-plans-header {
			br {
				display: none;
			}
			.display-numbers {
				float: right;
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.instant-quote-container {
		// Styles for the plan cards
		.best-plans-list,
		.other-plans-list {
			margin: 0;
			.plan-card {
				.more-details-link-row {
					width: 100%;
					position: absolute;
					bottom: 0;
					&.more-details-link-row-smaller {
						position: relative;
					}
				}
				&.customer-card {
					.more-details-link-row {
						width: initial;
						position: static;
						bottom: unset;
					}
				}
			}
		}
	}
}
