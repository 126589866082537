#bulkwindow {
	margin-left: 154px;
	width: 400px;
	position: absolute;
	display: none;
	background: white;
	box-shadow: #999 1px 5px 10px;
	padding: 10px;
	border-radius: 10px;
	z-index: $bulk-window-z;

	hr {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.dropped-location {
	.panel {
		&.card {
			border: 1px solid $red !important;
			.card-header {
				background-color: $faintPink !important;
				a {
					span {
						color: $red !important;
						a {
							color: $red !important;
						}
					}
				}
			}
		}
	}
}

.home-page-container {
	.table-container {
		overflow-x: scroll;
		display: inline;
		.table-striped {
			border: 1px solid $outlineGray;
			thead {
				tr {
					th {
						border-top: 1px solid $outlineGray;
						border-right: 1px solid $outlineGray;
						border-left: 1px solid $outlineGray;
						border-bottom: 2px solid $outlineGray;
						padding: 1rem;
					}
				}
			}
			tbody {
				tr {
					th {
						font-weight: 600;
						border: 1px solid $outlineGray;
						padding: 1rem;
					}
					td {
						border: 1px solid $outlineGray;
						padding: 1rem;
						p {
							strong,
							b {
								font-weight: 600;
							}
						}
					}
					&:nth-of-type(odd) {
						background-color: $white;
					}
					&:nth-of-type(even) {
						background-color: $offRowGray;
					}
					&.total-row {
						background-color: $placeholderGray;
						color: $white;
						font-weight: 600;
						font-size: 1.1rem;
					}
					&.vert-align-middle {
						td {
							vertical-align: middle !important;
						}
					}
					&.red-row {
						background-color: $faintPink;
						color: $red;
						font-weight: 600;
					}
					&.orange-row {
						background-color: $faintOrange;
						color: $orange;
						font-weight: 600;
					}
					&.light-gray-row {
						background-color: $lightGray;
						font-weight: 600;
					}
					&.gray-row {
						background-color: $offRowGray;
						font-weight: 600;
					}
				}
			}
			&.reduce-cell-padding {
				thead {
					tr {
						th {
							padding: 0.75rem;
						}
					}
				}
				tbody {
					tr {
						th {
							padding: 0.75rem;
						}
						td {
							padding: 0.75rem;
						}
					}
				}
			}
		}
	}

	// Market Recommendations
	.market-recommendations {
		border: 1px solid $pkBlue;
		border-radius: 0.5rem;
		background-color: $offRowGray;
		-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
		margin: 0 1rem;
		padding-top: 1rem;
		// Rate Suggestion Card
		.rate-suggestion-card {
			background-color: $white !important;
			border: 1px solid $blueGray;
			border-radius: 0.25rem;
			.rate-suggestion-body {
				color: $darkGray;
			}
			&:link,
			&:visited {
			}
			&:hover {
				background-color: $faintBlue !important;
				border: 1px solid $iconLinkBlue;
				.rate-suggestion-body {
					cursor: pointer;
					color: $black;
				}
			}
			&:active,
			&.active,
			&:focus {
				background-color: $iconLinkBlue !important;
				border: 1px solid $iconLinkBlue;
				.rate-suggestion-body {
					cursor: pointer;
					color: $white;
				}
			}
		}
	}
}

// Starting a new container as to not interfere with the old.
.contract-info-container {
	overflow: auto;
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		padding-bottom: 0.25rem;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		padding-bottom: 0.25rem;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
		}
		&.gray {
			color: $darkGray;
		}
	}

	.rounded-div {
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		padding-top: 1rem;
	}

	.collapse-div {
		.collapse-link {
			&:link,
			&:visited,
			&:active,
			&:focus {
				display: block;
				color: $pkBlue !important;
				i,
				fa-icon > svg {
					color: $iconLinkBlue !important;
				}
			}
			&:hover {
				color: $pkBlue !important;
				i,
				fa-icon > svg {
					color: $blue;
				}
			}

			.collapse-right-side {
				float: right;
			}
			.details-text {
				display: none;
				font-size: 0.85rem;
			}
			i,
			fa-icon > svg {
				transition: 0.3s transform ease-in-out;
				color: $iconLinkBlue !important;
				vertical-align: baseline;
			}
			.fa {
				font-size: 1rem !important;
			}
			&.collapsed {
				.details-text {
					display: none;
				}
				i,
				fa-icon > svg {
					transform: rotate(180deg);
				}
			}
		}
		.layer-strip {
			border: 1px solid $outlineGray;
			background-color: $offWhite;
			border-radius: 0 0 0.5rem 0.5rem;
			padding: 1rem;
		}
	}

	.lp-contract-selection {
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		margin-bottom: 1.5rem;
		transition:
			background-color 0.5s ease-in-out,
			border 0.5s ease-in-out;
		.commodity-icon {
			height: 3rem;
			width: auto;
		}
		h2 {
			margin-bottom: 0;
		}
		&:hover {
			background-color: $faintBlue;
			border: 1px solid $darkBlue;
			transition:
				background-color 0.5s ease-in-out,
				border 0.5s ease-in-out;
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.contract-info-container {
		.collapse-div {
			.collapse-link {
				&:link,
				&:visited,
				&:active,
				&:focus {
					.details-text {
						color: $lightBlue;
					}
				}
				&:hover {
					.details-text {
						color: $blue;
					}
				}
				&.collapsed {
					.details-text {
						display: inline;
						margin-right: 0.25rem;
					}
				}
			}
		}
	}
}
