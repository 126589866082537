.tooltip {
	z-index: $tooltip-z;
}
.risk-level-tooltip {
	margin: 0;
	padding-left: 15px;
	font-size: 1;
	text-align: left;
}
.issue-tooltip {
	margin: 0;
	padding-left: 15px;
	font-size: 7;
	text-align: left;
}
.issue-tooltip-red {
	color: $red !important;
}
.issue-tooltip-orange {
	color: $orange !important;
}
.issue-tooltip-normal {
	color: $blue !important;
}
