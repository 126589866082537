.academy-container {
	overflow: auto;
	min-height: calc(100% - 61px);
	padding: 8rem 2rem 2rem 2rem;

	.header-img {
		background-image: url('https://assets.powerkiosk.com/includes/academy/images/aa-top-bg.jpg');
		background-size: cover;
		background-position: center center;
		.top-logo {
			max-width: 18rem;
			width: 100%;
			height: auto;
			filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.75));
		}
	}

	.main-module {
		margin-bottom: 1.5rem;
		.main-mod-toggle {
			&:link,
			&:visited,
			&:active,
			&:focus {
				border-top: 1px solid $outlineGray;
				border-left: 1px solid $outlineGray;
				border-right: 1px solid $outlineGray;
				border-bottom: none;
				background-color: $white;
				border-radius: 0.5rem 0.5rem 0 0;
				padding: 1rem;
				.aa-icon {
					width: 5rem;
					height: auto;
					filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
				}
				.main-module-header {
					margin-bottom: 0;
				}
				.main-module-one-liner {
					font-size: 0.85rem;
					font-weight: 500;
					color: $darkGray;
					margin-bottom: 0;
				}
			}
			&:hover {
				.aa-icon {
					filter: none;
				}
				.main-module-header {
					color: $black;
				}
				.collapse-symbol {
					color: $black;
					i,
					fa-icon {
						color: $black;
					}
				}
			}
			&.collapsed {
				&:link,
				&:visited,
				&:active,
				&:focus {
					border: 1px solid $outlineGray;
					background-color: $offWhite;
					border-radius: 0.5rem;
					.main-module-one-liner {
						font-weight: 500;
						color: $darkGray;
					}
				}
				&:hover {
					border: 1px solid $blue;
					background-color: $white;
					.main-module-header {
						color: $black;
					}
					.main-module-one-liner {
						color: $black;
					}
					.collapse-symbol {
						color: $black;
						i,
						fa-icon {
							color: $black;
						}
					}
				}
			}
			&.inactive {
				&:link,
				&:visited,
				&:active,
				&:focus,
				&:hover {
					border: 1px solid $outlineGray;
					background-color: $offWhite;
					border-radius: 0.5rem;
					cursor: default;
					.main-module-header {
						color: $pkBlue;
					}
					.main-module-one-liner {
						font-weight: 500;
						color: $darkGray;
					}
				}
			}
		}
		.main-mod-collapse-div {
			border-top: none;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			background-color: $white;
			border-radius: 0 0 0.5rem 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
			.module-collapse-div {
				.indiv-lesson {
					padding-top: 1rem;
					padding-bottom: 1rem;
					border-top: 1px solid $outlineGray;
					h5 {
						font-size: 0.95rem;
						font-weight: 600;
						margin-bottom: 0.25rem;
						a {
							&:link,
							&:visited,
							&:active {
								color: $darkBlue;
							}
							&:hover,
							&:focus {
								color: $lightBlue;
							}
						}
					}
					p {
						font-size: 0.8rem;
						margin-bottom: 0;
					}
					a {
						&.btn-lesson-start {
							text-transform: uppercase;
							font-size: 0.85rem;
							font-weight: 700;
							&:link,
							&:visited,
							&:active {
								background-color: $lightBlue;
								border-color: $lightBlue;
								color: $white;
								span::before {
									content: 'Start';
								}
							}
							&:hover,
							&:focus {
								background-color: $blue;
								border-color: $blue;
								color: $white;
							}
							&.visited {
								&:link,
								&:visited,
								&:active {
									background-color: $halfBlue;
									border-color: $halfBlue;
									color: $blue;
									span::before {
										content: 'Open';
									}
								}
								&:hover,
								&:focus {
									background-color: $blue;
									border-color: $blue;
									color: $white;
								}
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	.academy-container {
		.header-img {
			.top-logo {
				max-width: 25rem;
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.academy-container {
		.main-module {
			max-width: 1080px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
