.tt_sm {
	border-radius: 5px;
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
	z-index: 1000000;
	background-color: #e4e4e4;
	padding: 7px;
	opacity: 0.9;
	font:
		20px/1.5 Verdana,
		Arial,
		Helvetica,
		sans-serif;
	color: black;
}
.tt_custom_sm {
	overflow-y: auto;
	max-height: 400px;
	content: '';
	.table {
		th {
			background-color: #1c2674 !important;
			border-color: #1c2674 !important;
			color: white !important;
		}
		td,
		th {
			padding-top: 2px !important;
			padding-bottom: 2px !important;
		}
		&.table-bordered {
			td {
				border-color: #454d55 !important;
			}
		}
	}
}
.tt_name_sm {
	float: left;
	font-weight: bold;
	max-height: 1px;
}
.xmark_sm {
	float: right;
	margin-left: 5px;
	cursor: pointer;
	line-height: 0px;
}
.tt_mobile_sm {
	margin-top: 5px;
}
.btn_simplemaps {
	color: black;
	text-decoration: none;
	background: #ffffff;
	display: inline-block;
	padding: 5px 5px;
	margin: 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	line-height: 1.43;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid;
	border-radius: 4px;
}
.btn_simplemaps:hover {
	text-decoration: underline;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.map-scroll {
	overflow-y: auto;
	max-height: 400px;
}

.map-div {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
	opacity: 0;
	-webkit-animation: fade-in 0.5s ease-out 1s forwards;
	animation: fade-in 0.5s ease-out 1s forwards;
}

.map-table {
	border-collapse: collapse;
	width: 100%;
	color: $darkGray;
}

.map-table thead th {
	width: 50%;
	font-weight: bold;
	color: $black;
	border-top: none;
	border-bottom: 1px solid $darkBlue;
	padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.map-table thead th:first-child {
	padding: 0.25rem 1rem 0.25rem 0.25rem;
}

.map-table tbody td {
	width: 50%;
	padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.map-table tbody td:first-child {
	border-right: 1px solid $darkBlue;
	padding: 0.25rem 1rem 0.25rem 0.25rem;
}

.tt_name_sm {
	text-transform: uppercase;
	width: 100%;
	text-align: center;
	padding-bottom: 1rem;
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
	.map-inner {
		max-height: 1080px !important;
	}

	.map-filter-controls {
		display: flex;
		justify-content: center;
	}

	.map-filter-div {
		flex: 0 0 auto;
		width: auto;
	}

	.map-filter-div .map-filter-left,
	.map-filter-div .map-filter-center,
	.map-filter-div .map-filter-right {
		width: auto;
		display: inline-block;
	}

	.map-filter-div .map-filter-left {
		border-radius: 0.25rem 0 0 0.25rem;
	}

	.map-filter-div .map-filter-center {
		border-left: 1px solid #cccccc;
		border-right: 1px solid #cccccc;
	}

	.map-filter-div .map-filter-right {
		border-radius: 0 0.25rem 0.25rem 0;
	}
}
