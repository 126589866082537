.dx-overlay-wrapper {
	z-index: $dx-overlay-wrapper-z !important;
}

.table-sm {
	table {
		td {
			i,
			fa-icon {
				font-size: 0.875rem;
			}
		}
	}
}

.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
	color: white !important;
	background-color: #1e91d6 !important;
}

.dx-popup-wrapper {
	z-index: $dx-popup-wrapper-z !important;
}

.dx-datagrid-rowsview .dx-row > td {
	overflow: hidden !important;
}

.dx-datagrid-content {
	.btn-group {
		.btn {
			padding: 0.75em 1.5em !important;
		}
	}
	.btn,
	a {
		i,
		fa-icon {
			font-size: 1rem;
			vertical-align: middle;
			margin-right: 0rem;
		}
	}

	.table-danger,
	.table-danger > th,
	.table-danger > td {
		background-color: #f5c6cb !important;
	}

	.red-row,
	.red-row > th,
	.red-row > td {
		background-color: $faintPink !important;
	}

	.table-warning,
	.table-warning > th,
	.table-warning > td {
		background-color: #ffeeba !important;
	}

	.orange-row,
	.orange-row > th,
	.orange-row > td {
		background-color: $faintOrange !important;
	}

	.table-danger-warning,
	.table-danger-warning > th,
	.table-danger-warning > td {
		background: linear-gradient(to bottom, #f5c6cb 50%, #ffeeba 0%);
	}

	.green-row,
	.green-row > th,
	.green-row > td {
		background-color: $faintGreen !important;
	}
}

.dx-data-row > td,
.dx-data-row > td a {
	// color: rgba(0, 0, 0, 0.87);
	font-weight: 400;
}

.dx-data-row > td {
	overflow: hidden !important;
}

.dx-widget {
	font-family: 'Roboto', sans-serif !important;
}

.dx-header-row > td[role='columnheader'] > div.dx-datagrid-text-content {
	// color: rgb(0, 0, 0) !important;
	font-weight: 400;
}

.dx-datagrid-table
	.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
	> td:not(.dx-focused) {
	background: unset;
	background-color: #e7e7e7 !important;
	color: #333;
	transition: 0.5s;
}

.dx-last-cell {
	border-left: 4px solid #ccc !important;
}
