// Monthly Grid
.monthly-grid {
	overflow-x: scroll;
	.card {
		min-width: 14rem;
		background-color: $offWhite;
		border: 1px solid $silver;
		margin-right: 1rem;
		margin-bottom: 1rem;
		.card-body {
			padding: 1rem;
			.card-title {
				color: $pkBlue;
				font-weight: 600;
				text-align: right;
				border-bottom: 1px solid $outlineGray;
				text-transform: uppercase;
			}
			.big-number {
				font-size: 1.5rem;
				sup {
					font-size: 0.85rem;
				}
			}
		}
		.card-footer {
			background: none;
			border: none;
			padding: 0;
			.btn {
				border-radius: 0 0 0.25rem 0.25rem;
				padding: 0.75em 0em;
			}
		}
		&.red-card {
			background-color: $faintPink;
			border: 1px solid $red;
			.card-body {
				.card-title {
					color: $red;
					border-bottom: 1px solid $darkerOutlineGray;
					margin-bottom: 0.2rem;
				}
				.past-due {
					color: $red;
					font-size: 0.7rem;
					font-weight: 600;
					text-align: right;
					text-transform: uppercase;
					margin-bottom: 0.25rem;
				}
				.blue-text {
					color: $red !important;
				}
				.big-number {
					color: $red;
				}
			}
			.card-footer {
				.btn-info {
					background-color: $red !important;
					border: 1px solid $red;
					color: $white;
					&:hover {
						background: $pink !important;
						border: 1px solid $pink;
						color: $red !important;
					}
				}
			}
		}
		&.empty-card {
			border: 1px solid $offWhite;
			.card-body {
				.card-title {
					color: $darkGray;
				}
			}
		}
	}
}
