/* override dx */
.dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
	min-height: 100% !important;
}

.gridTopScroll {
	width: 100%;
	height: 20px;
	overflow-x: scroll;
	overflow-y: hidden;
}

.gridTopScrollInner {
	width: 3000px;
	height: 20px;
}

.dx-datagrid-table {
	td[aria-sort='descending'],
	td[aria-sort='ascending'] {
		background-color: rgba(141, 192, 219, 0.25);
	}
}
