// 	Styles specific for Setup pages

.setup-container {
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 0.85rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h4 {
		font-size: 0.85rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	.contracts-table-header {
		.dropdown {
			.dropdown-menu {
				.dropdown-subhead {
					font-size: 1rem;
					border-bottom: 1px solid $pkBlue;
					font-weight: 600;
					margin-bottom: 0.5rem;
					padding-left: 0;
				}
				.default-select {
					display: block;
					padding-top: 2px;
					padding-left: 2px;
					padding-right: 10px;
					&:link,
					&:visited,
					&:active {
						color: $halfBlue;
						&.default-item {
							color: $pkBlue;
						}
					}
					&:hover,
					&:focus {
						color: $tooltipBlue;
						&.default-item {
							color: $pkBlue;
						}
					}
				}
				.dropdown-item {
					font-size: 0.8rem;
					padding: 0.25rem 0.5rem 0.25rem 0.5rem;
				}
			}
		}
	}

	img {
		&.energy-icon {
			height: 1.25rem;
			width: auto;
		}
	}

	// Report Form Container
	.reports-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			border-radius: 0 0 0.5rem 0.5rem;
			.main-form-row {
				padding: 1.5rem 1rem 2.5rem;
				.reports-switch-label {
					line-height: 1.5rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				&.no-button {
					padding: 1.5rem 1rem 0.5rem;
				}
				// Makes the label light blue when switch is checked
				.custom-control-input:checked + .reports-switch-label {
					color: $lightBlue;
					font-weight: 600;
				}
			}
			.shifted-button {
				position: relative;
				.btn {
					position: absolute;
					top: -1.15rem;
				}
			}
			.shifted-button-div {
				position: relative;
				.shifted-div {
					position: absolute;
					top: -1.15rem;
				}
			}
		}
	}

	.bordered-div {
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		padding: 1rem;
	}

	// Email Preferences
	.email-thumb {
		padding-right: 0;
		a {
			img {
				border: 1px solid $pkBlue;
			}
		}
	}
	.email-desc {
		font-size: 0.75rem;
	}
	.email-enable-switch {
		.custom-control-input + .custom-control-label span::after {
			content: 'Off';
			text-transform: none;
			color: $darkGray;
			font-weight: 700;
			line-height: 1.5rem;
		}
		.custom-control-input:checked + .custom-control-label span::after {
			content: 'On';
			text-transform: none;
			color: $lightBlue;
			font-weight: 700;
			line-height: 1.5rem;
		}
	}

	// Cards for the Pop-Up Management
	.card {
		&.popup-listing-item {
			border: 1px solid $outlineGray;
			.card-title {
				font-size: 1.1rem;
				margin-bottom: 0.25rem;
			}
			.card-text {
				font-size: 0.85rem;
				a {
					display: flex;
					align-items: center;
					gap: 5px;
					&.priority-link {
						&:link,
						&:visited,
						&:active,
						&:focus {
							font-weight: 600;
							color: $blue;
							i,
							fa-icon {
								color: $darkerOutlineGray;
								margin-right: 3px;
								vertical-align: text-bottom;
							}
						}
						&:hover {
							font-weight: 600;
							color: $darkBlue;
							i,
							fa-icon {
								color: $darkGray;
							}
						}
					}
				}
			}
		}
	}

	#rfq-settings-table {
		table {
			margin-bottom: 0;
			tr:not(.dx-freespace-row) {
				.dx-command-select {
					background-color: $blueGray !important;
					color: $white !important;
				}
			}
		}
	}

	.dark-logo-preview {
		background-color: $pkBlue;
		padding: 0.75rem;
		width: 100%;
		img {
			height: 45px;
			width: auto;
		}
	}

	.light-logo-preview {
		background-color: $offWhite;
		padding: 0.75rem;
		width: 100%;
		img {
			height: 45px;
			width: auto;
		}
	}

	// Email Card
	.email-card {
		border-color: $darkGray;
		border-radius: 0.5rem;
		background-color: $offWhite;
		margin-bottom: 1rem;
		height: 100%;
		.card-body {
			padding-bottom: 0;
			.card-title {
				font-size: 1.15rem;
				color: $pkBlue;
			}
			.card-text {
				margin-bottom: 1rem;
			}
		}
		.card-footer {
			background: none;
			border: none;
			.card-link {
				font-weight: 600;
			}
			&.condensed {
				font-size: 0.8rem;
				padding-left: 0.75rem;
				padding-right: 0.75rem;
				.card-link {
					i,
					fa-icon {
						margin-right: 0.3rem;
					}
					& + .card-link {
						margin-left: 0.5rem;
					}
				}
			}
		}
		&.card-stack {
			box-shadow:
				5px 5px 0 -1px $offWhite,
				5px 5px $darkGray,
				10px 10px 0 -1px $offWhite,
				10px 10px $darkGray,
				15px 15px 0 -1px $offWhite,
				15px 15px $darkGray;
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.setup-container {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.25rem;
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
}

// 	Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

// 	Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}
