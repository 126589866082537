.alert-notification {
	font-size: 0.85rem;
	background-color: $offWhite;
	border: 1px solid $iconLinkBlue;
	border-radius: 0.5rem;
	margin-bottom: 2rem;
	padding: 0.5rem 1rem;
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
	.alert-layout {
		max-width: 992px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		.alert-icon {
			color: $iconLinkBlue;
			font-size: 3rem;
			text-align: right;
			&.add-pulse {
				i,
				fa-icon {
					animation: icon-pulse 3.5s infinite ease;
				}
			}
		}
		.alert-content {
			p {
				margin-bottom: 0;
				&.header {
					font-size: 1rem;
					font-weight: 700;
					color: $pkBlue;
				}
			}
		}
		// Close Button
		.close {
			opacity: 1;
			position: absolute;
			top: -5px;
			right: 0;
			z-index: 2;
			padding: 0.75rem 1.25rem;
			&:link,
			&:visited,
			&:active {
				opacity: 1;
				i,
				fa-icon {
					color: $lightBlue;
					font-size: 1.25rem;
					vertical-align: unset;
					margin-right: 0;
				}
			}
			&:hover,
			&:focus {
				opacity: 1;
				i,
				fa-icon {
					color: $blue;
					font-size: 1.25rem;
				}
			}
		}
		a:not([href]):not([tabindex]) {
			color: $lightBlue;
			font-size: 1.25rem;
		}
	}
	&.alert-width-limit {
		max-width: 768px;
		margin-left: auto;
		margin-right: auto;
	}
	&.green-alert {
		border: 1px solid $green;
		.alert-icon {
			color: $green;
		}
		.alert-content {
			p {
				&.header {
					color: $green;
				}
			}
		}
	}
	&.red-alert {
		border: 1px solid $red;
		.alert-icon {
			color: $red;
		}
		.alert-content {
			p {
				&.header {
					color: $red;
				}
			}
		}
	}
	&.orange-alert {
		border: 1px solid $orange;
		.alert-icon {
			color: $orange;
		}
		.alert-content {
			p {
				&.header {
					color: $orange;
				}
			}
		}
	}
}
