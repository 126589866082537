// 	Styles for elements in Market Analytics

.market-analytics-container {
	font-family: $mainFont;
	background: $white;
	min-height: calc(100% - 61px);
	padding: 9rem 2rem 3rem 2rem;
	font-size: 0.75rem;
	.market-analytics-form {
		border: 2px solid $blue;
		border-radius: 0.5rem;
		.container-fluid,
		&.container-fluid {
			padding-right: 15px;
			padding-left: 15px;
		}
		// Top row of main content
		.top-row {
			background: $blue;
			color: $white !important;
			font-weight: 600;
			padding-top: 0.25rem;
			.energy-icon {
				padding: 2.25rem 1.25rem;
				img {
					height: 2.25rem;
				}
			}
			.energy-header {
				padding: 1rem;
				h1 {
					font-size: 1.5rem;
					font-weight: 600;
					margin: 0 0 3px 0;
					color: $white;
				}
				p {
					font-weight: 500;
					margin: 0 0 3px 0;
					color: $white !important;
				}
			}
			.energy-period {
				padding: 0.75rem 0.85rem;
				.energy-period-container {
					border: 1px solid #5278b0;
					border-radius: 0.5rem;
					.calendar-icon {
						background: #5278b0;
						padding: 1.5rem 0.75rem;
						i,
						fa-icon {
							font-size: 1.25rem;
							line-height: unset;
						}
					}
					.energy-period-labels {
						color: $halfWhite;
						padding: 0.85rem 4px 0 1.75rem;
						p {
							margin: 0 0 0.6.75rem 0;
							color: $halfWhite !important;
						}
					}
					.energy-period-dates {
						padding: 0.85rem 1.75rem 0 4px;
						p {
							margin: 0 0 0.6.75rem 0;
							color: $white !important;
						}
					}
				}
			}
			.energy-top-info-div {
				padding: 0.75rem 1.75rem 1rem;
			}
			.energy-location,
			.energy-threshold,
			.energy-usage,
			.energy-utility {
				p {
					margin: 0 0 3px 0;
					color: $white !important;
					&.half-white {
						color: $halfWhite !important;
					}
				}
			}
			.energy-toggle {
				text-align: right;
				padding: 34px 21px 0 0;
				i,
				fa-icon {
					cursor: pointer;
					font-size: 1.75rem;
					color: $halfWhite;
					line-height: unset;
					&:hover {
						color: $white;
					}
				}
			}
			.disabled {
				i,
				fa-icon {
					visibility: hidden;
				}
			}
		}
		.bottom-row {
			tabset {
				width: 100%;
			}
			// Tabs for the lower half of the content area
			.nav-tabs {
				width: 100%;
				background: $blue;
				font-size: 0.8rem;
				.nav-item {
					margin-bottom: 0;
					&:hover,
					&:focus {
						background-color: $darkBlue !important;
					}
					.nav-link {
						border: none;
						padding: 0.85rem 2rem 1rem 2rem;
						&:link,
						&:visited {
							color: $white !important;
						}
						&.active {
							background: none;
							font-weight: 700;
							border-bottom: 3px solid $white;
						}
					}
				}
			}
			// Content within tabs
			.tab-content {
				width: 100%;
				margin-top: 0 !important;
				.container-fluid {
					padding-top: 15px !important;
				}
				.tab-pane {
					.bootstrap-datetimepicker-widget {
						font-family: $helvetica;
					}
					// Wholesale content tab
					.wholesale-content {
						padding: 0.85rem 1.75rem;
						.form-column-1 {
							border-right: none;
							padding: 0.85rem 0.85rem 2.75rem 0.85rem;
							div {
								.label-style {
									color: $black !important;
									font-weight: 500;
									font-size: 0.75rem;
									margin: 0 0 4px 0;
								}
							}
							.form-service {
								padding-bottom: 0.85rem;
								.dropdown-toggle {
									text-transform: uppercase;
									font-weight: 700;
									font-size: 0.75rem;
									border: 1px solid $blue;
									padding: 0.5rem 1rem;
									width: 100%;
									display: inline-block;
									&:link,
									&:visited {
										color: $black !important;
										background-color: $white !important;
										text-decoration: none;
										border-radius: 0.5rem;
									}
									&:hover {
										color: $white !important;
										background-color: $blue !important;
										border-radius: 0.5rem;
									}
									&:focus {
										border-radius: 4px 4px 0 0;
									}
									img {
										width: 1rem;
										margin-right: 0.85rem;
									}
								}
								.dropdown-menu {
									padding: 0;
									width: 100%;
									margin: 0;
									.dropdown-item {
										text-transform: uppercase;
										font-weight: 700;
										font-size: 0.75rem;
										border: 1px solid $blue;
										padding: 0.5rem 1rem;
										width: 100%;
										margin: 0;
										border-radius: 0 0 4px 4px;
										&:link,
										&:visited {
											color: $black;
											background-color: $white !important;
											text-decoration: none;
										}
										&:hover,
										&:focus {
											color: $white;
											background-color: $blue !important;
										}
										img {
											width: 1rem;
											margin-right: 0.85rem;
										}
									}
								}
							}
							.form-period {
								.list-group {
									.list-group-item {
										user-select: none;
										text-transform: uppercase;
										font-weight: 700;
										font-size: 0.75rem;
										border: 1px solid $blue;
										margin-bottom: 0 !important;
										&:nth-child(2) {
											border-radius: 4px 4px 0 0;
										}
										&:nth-child(6) {
											border-radius: 0 0 4px 4px;
										}
										.small-text {
											font-size: 0.75rem;
											width: 10rem;
											display: inline-block;
											vertical-align: bottom;
											margin-left: 0.25rem;
										}
									}
									& input[type='radio'] {
										display: none;
										& + .list-group-item {
											color: #212529 !important;
											cursor: pointer;
											&:before {
												content: '';
												color: transparent;
												font-weight: bold;
											}
										}
										&:checked + .list-group-item {
											background-color: $blue;
											color: $white !important;
											&:before {
												color: inherit;
											}
										}
									}
								}
							}
						}
						.form-column-2 {
							padding: 1.75rem 0.85rem 0.85rem 0.85rem;
							.form-row {
								.form-element-container {
									padding-bottom: 0.85rem;
									label {
										color: $black !important;
										font-weight: 500;
										font-size: 0.75rem;
										margin: 0 0 4px 0;
									}
									.input-group {
										.input-group-append {
											.input-group-text {
												background: $gray;
												color: $blue;
											}
										}
									}
								}
							}
						}
					}
					// Retail content tab
					.retail-content {
						background-color: $pkBlue !important;
						color: $white !important;
						padding-top: 1.75rem !important;
						padding-bottom: 0.85rem !important;
						// Statistics sitting at the top of the area
						.top-row-stats {
							padding: 0 0.85rem;
							.stat-title {
								color: $halfWhite !important;
								font-size: 0.75rem;
								margin: 0;
							}
							.stat-number {
								font-weight: 600;
								font-size: 1.75rem;
								color: $white !important;
								line-height: 2rem;
								span {
									&.unit-of-measure {
										font-size: 0.75rem;
										font-weight: 500;
									}
								}
								sup {
									&.unit-of-measure {
										font-size: 0.75rem;
										font-weight: 500;
										top: -0.75rem;
										margin-left: 0.25rem;
									}
								}
							}
							.stat-number-auction {
								font-size: 1.75rem;
								color: #12a5ea !important;
								margin-bottom: 0;
							}
							.stat-number-auction-disclaimer {
								color: #12a5ea !important;
								font-weight: 600;
								font-size: 0.75rem;
							}
							// Legend Popper
							.legend-popper {
								text-align: right;
								padding: 0;
								.legend-open {
									position: absolute;
									top: 8px;
									right: 6px;
									i,
									fa-icon {
										color: $halfWhite !important;
										font-size: 1.75rem;
										&:hover {
											color: $white !important;
										}
									}
								}
								.legend-area {
									position: absolute;
									top: -14px;
									right: -9px;
									z-index: 100;
									width: 21.75rem;
									background-color: $white !important;
									border-radius: 0.5rem;
									padding: 0.85rem 0.85rem 0.85rem 1.75rem;
									color: $pkBlue !important;
									font-size: 0.85rem;
									p {
										text-align: left;
										&.legend-close {
											text-align: right;
											font-size: 1.85rem;
											margin: 0 0 -0.85rem 0;
											a {
												i,
												fa-icon {
													color: rgba(0, 0, 0, 0.5);
													&:hover,
													&:focus {
														color: $black !important;
													}
												}
											}
										}
										.energy-color {
											font-weight: 700;
											color: $lightBlue !important;
										}
										.capacity-color {
											font-weight: 700;
											color: $orange !important;
										}
										.ancillary-color {
											font-weight: 700;
											color: $green !important;
										}
									}
								}
							}
						}
						.nymex-opening {
							color: $offWhite;
							h2 {
								color: $white;
							}
							.henry-hub-title {
								font-size: 0.85rem;
								color: $white;
								margin: 0 0 -0.5rem 0;
							}
							.henry-hub-number {
								font-size: 3rem;
								font-weight: 600;
								margin: 0 0 -0.5rem 0;
								.cents-sub {
									font-size: 0.7rem;
									top: -1.65rem;
									font-weight: 500;
									position: relative;
								}
							}
							.henry-hub-timestamp {
								font-size: 0.75rem;
								color: $white;
								margin-bottom: 2rem;
							}
						}
						.nymex-time-filters {
							.col-auto {
								padding-left: 5px;
								padding-right: 5px;
								a {
									&.nymex-time-link {
										font-weight: 600;
										font-size: 1rem;
										padding-left: 10px;
										padding-right: 10px;
										padding-bottom: 3px;
										&:link,
										&:visited {
											color: $tooltipBlue;
										}
										&:active {
											color: $white;
											border-bottom: 2px solid $white;
										}
										&:hover,
										&:focus {
											color: $diamond;
											border-bottom: 2px solid $diamond;
										}
										&.active {
											&:link,
											&:visited,
											&:active,
											&:hover,
											&:focus {
												color: $white;
												border-bottom: 2px solid $white;
											}
										}
									}
								}
							}
						}
						.nymex-table-div {
							margin-top: 3rem;
							color: $offWhite;
							h2 {
								color: $white;
							}
							hr {
								border-top: 1px solid $halfBlue;
							}
							a {
								&:link,
								&:visited,
								&:active {
									color: $tooltipBlue;
								}
								&:hover,
								&:focus {
									color: $diamond;
								}
							}
							.nymex-table {
								color: $offWhite;
								thead {
									th {
										border-top: 0;
									}
								}
							}
						}
						.chart-and-side-stats {
							.nymex-chart-div {
								.chartContainer {
									height: 25rem;
								}
							}
							// Container for the canvasjs chart
							.chart-div {
								.chartContainer {
									height: 24rem;
								}
							}
							// Statistics that sit below or to the right of the chart
							.side-stats {
								padding-top: 1.75rem;
								.pieContainer {
									height: 15em;
								}
								.side-stat-div {
									color: $halfWhite !important;
									font-size: 0.75rem;
									.side-stat-title {
										color: $white !important;
										font-weight: 600;
										font-size: 1.25rem;
									}
									.avg-price {
										margin: -4px 0 -3px 2.75rem;
										color: $halfWhite !important;
										line-height: 1.25;
									}
									.side-stat-number {
										font-weight: 600;
										font-size: 2.5rem;
										margin: 0 0 0.6.75rem 0.85rem;
										line-height: 1.25;
										.unit-of-measure {
											font-size: 0.75rem;
											top: -1.5rem;
											font-weight: 500;
											position: relative;
										}
									}
								}
							}
						}
						.form-element-container {
							label {
								color: $white;
							}
							// Custom Switches
							.custom-switch {
								// Makes the label light blue when switch is checked
								.custom-control-input:checked ~ .custom-switch-label {
									color: $lightBlue;
									font-weight: 600;
									&::before {
										border-color: $lightBlue;
										background-color: $lightBlue;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	body {
		.main-content-container {
			.market-analytics-form {
				.container-fluid,
				&.container-fluid {
					padding-right: 15px;
					padding-left: 15px;
				}
				.bottom-row {
					// Content within tabs
					.tab-content {
						.tab-pane {
							// Retail content tab
							.retail-content {
								// Statistics that sit below or to the right of the chart
								.side-stats {
									.side-stat-div {
										.side-stat-title {
											font-size: 1.6.75rem;
										}
										.side-stat-number {
											font-size: 2.75em;
										}
										.legend-popper {
											.legend-open {
												right: 21px;
											}
											.legend-area {
												right: 6px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	body {
		.main-content-container {
			padding: 0;
			font-size: 0.85rem;
			.market-analytics-form {
				.container-fluid,
				&.container-fluid {
					padding-right: 15px;
					padding-left: 15px;
				}
				// Top row of main content
				.top-row {
					.energy-top-info-div {
						padding: 0.85rem 1.75rem 0;
					}
				}
				.bottom-row {
					// Content within tabs
					.tab-content {
						.tab-pane {
							// General styles for form elements
							.btn {
								padding: 0.5rem 2.75rem;
								width: auto;
							}
							// Wholesale content tab
							.wholesale-content {
								.form-column-1 {
									border-right: 1px solid $gray;
									border-bottom: none;
									padding: 0.85rem 1.75rem 0.85rem 0.85rem;
								}
								.form-column-2 {
									padding: 0.85rem 0.85rem 0.85rem 1.75rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	body {
		.main-content-container {
			.market-analytics-form {
				.container-fluid,
				&.container-fluid {
					padding-right: 15px;
					padding-left: 15px;
				}
				.bottom-row {
					// Content within tabs
					.tab-content {
						.tab-pane {
							// General styles for form elements
							.btn {
								padding: 0.5rem 3.5rem;
							}
							// Wholesale content tab
							.wholesale-content {
								.form-column-1 {
									padding: 0.85rem 2.75rem 0.85rem 0.85rem;
								}
								.form-column-2 {
									padding: 0.85rem 0.85rem 0.85rem 2.75rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.

@media (min-width: 1200px) {
	body {
		.main-content-container {
			.market-analytics-form {
				.container-fluid,
				&.container-fluid {
					padding-right: 15px;
					padding-left: 15px;
				}
				.bottom-row {
					// Content within tabs
					.tab-content {
						.tab-pane {
							// Retail content tab
							.retail-content {
								// Statistics that sit below or to the right of the chart
								.side-stats {
									padding-top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
