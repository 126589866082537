// 	Styles specific for the Support Center

.support-center-container,
.pk-modal-support-center {
	overflow: auto;
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;
	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		border-bottom: 1px solid $pkBlue;
		padding-bottom: 0.25rem;
		&.no-underline {
			border-bottom: none;
		}
		&.gray {
			color: $darkGray;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	.modal-gray-area {
		background-color: $offRowGray;
		padding: 1rem 1.5rem 0.5rem;
		margin-bottom: 1.5rem;
	}

	.availability-alert {
		border-bottom: 2px solid $pkBlue;
		margin-bottom: 1.5rem;
		font-weight: 700;
		padding: 0.5rem 0 1.75rem;
		&.green {
			color: $green;
		}
		&.red {
			color: $red;
		}
	}

	.top-search-header {
		background-color: $offWhite;
		padding: 1rem;
		&.main-page-big {
			min-height: 14rem;
		}
		.main-search-faq {
			input {
				font-size: 1rem;
			}
			.btn {
				font-size: 1.25rem;
			}
		}
	}
	.icon-link {
		font-weight: 600;
		&:link,
		&:visited,
		&:active {
			.icon {
				vertical-align: middle;
				margin-right: 0.5rem;
				min-width: 2rem;
				display: inline-block;
				text-align: center;
				i,
				fa-icon {
					color: $blueGray;
					font-size: 1.5rem;
				}
			}
		}
		&:hover,
		&:focus {
			.icon {
				i,
				fa-icon {
					color: $blue;
				}
			}
		}
	}
	.faq-left {
		padding: 1rem 1rem 2rem 1rem;
	}
	.faq-right {
		border-top: 1px solid $outlineGray;
		padding: 2rem 1rem 1rem 1rem;
	}

	.media-entry {
		font-size: 0.75rem;
		padding-top: 1rem;
		border-radius: 0.35rem;
		h3 {
			font-size: 1rem;
			border-bottom: none;
			margin-bottom: 0;
			word-break: break-word;
		}
		.media-thumb {
			position: relative;
			img {
				border: 2px solid $outlineGray;
			}
			i,
			fa-icon {
				font-size: 1.5rem;
				position: absolute;
				bottom: 2px;
				right: 12px;
				color: rgba(63, 95, 128, 0.75);
			}
		}
		&:hover,
		&:focus {
			background-color: $lightGray;
			cursor: pointer;
			h3 {
				color: $black;
			}
			.media-thumb {
				img {
					border: 2px solid $pkBlue;
				}
			}
		}
	}

	.rounded-corner-div {
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		padding-top: 1rem;
		&.off-white-bg {
			background-color: $offWhite;
		}
		&.light-gray-bg {
			background-color: $lightGray;
		}
		&.off-row-gray-bg {
			background-color: $offRowGray;
		}
	}

	.dialogue-div {
		padding: 1rem;
		border-radius: 0.75rem;
		&.dialogue-message {
			border-radius: 0 0.75rem 0.75rem 0.75rem;
			margin: 0 1rem 2.5rem;
		}
		&.latest-post-bg {
			background-color: $white;
			border: 1px solid $darkBlue;
		}
		&.normal-message-bg {
			background-color: $offWhite;
			border: 1px solid $outlineGray;
		}
		&.private-message-bg {
			background-color: $halfBlue;
			border: 1px solid $tooltipBlue;
		}
		&.status-message-bg {
			background-color: $blueGray;
			border: 1px solid $blueGray;
			color: $white;
		}
		&.edit-message-bg {
			background-color: $white;
			border: 1px solid $lightBlue;
		}
	}
	.follow-up-collapse {
		border-top: 1px solid $pkBlue;
		padding-top: 1rem;
		margin-bottom: 1rem;
	}

	.support-center-buckets {
		font-size: 1rem;
		.main-bucket-link {
			padding: 0.75rem;
			border-radius: 0.5rem;
			display: block;
			transition: 0.5s;
			&:link,
			&:visited,
			&:active {
				background-color: $white;
				border: 1px solid $darkGray;
				color: $black;
				.bucket-icon,
				fa-icon {
					font-size: 4rem;
					color: $lightBlue;
					transition: 0.5s;
				}
				h2 {
					font-size: 1.2rem;
					color: $black;
				}
			}
			&:hover,
			&:focus {
				background-color: $halfBlue;
				border: 1px solid $pkBlue;
				color: $black;
				text-decoration: none;
				.bucket-icon {
					color: $pkBlue;
				}
				.btn-light {
					color: $white;
					background-color: $pkBlue;
				}
			}
		}
	}
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
	.support-center-container {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.5rem;
		}
		h3 {
			font-size: 1.25rem;
		}
		h4 {
			font-size: 1.15rem;
		}
	}
}

//  Styles for the large breakpoint.
@media (min-width: 992px) {
	.support-center-container {
		.top-search-header {
			padding: 2rem;
		}
		.faq-left {
			padding: 1rem 2rem 1rem 1rem;
		}
		.faq-right {
			border-top: none;
			border-left: 1px solid $outlineGray;
			padding: 1rem 1rem 1rem 2rem;
		}
	}
}
