.configure-table-modal {
	.left-column {
		.main-column-header {
			color: $black;
			font-weight: 600;
			font-size: 1em;
			border-bottom: 2px solid $pkBlue;
			& > div {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
			}
		}
		.column-choice {
			color: $black;
			font-weight: 500;
			font-size: 0.75em;
			& > div {
				padding-top: 0.75rem;
				padding-bottom: 0.25rem;
			}
			.show-hide-switch {
				label {
					&::before {
						top: 0;
					}
					&::after {
						top: calc(0.05em + 2px);
					}
					span {
						font-size: 0.675rem;
						&::after {
							content: 'Hidden';
						}
					}
				}
				.custom-control-input:checked ~ .custom-control-label span::after {
					content: 'Shown';
				}
			}
		}
	}
	.right-column {
		.main-column-header {
			color: $black;
			font-weight: 600;
			font-size: 1em;
			& > div {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
				&.col-8 {
					padding-bottom: 0.7rem;
				}
			}
		}
		.column-sort-headers {
			font-size: 0.85rem;
			font-weight: 700;
			border-bottom: 2px solid $pkBlue;
			margin-top: 1rem;
			padding-bottom: 0.25rem;
		}
		.column-sort-row {
			font-size: 0.75rem;
			margin-top: 1rem;
			.form-element-container {
				padding-bottom: 0;
			}
			i,
			fa-icon {
				font-size: 1.25rem;
			}
		}
	}
	.dx-list-item-content {
		padding: 5px 10px;
	}
}
