// 	Styles specific for the Supplier Home Page and Bid Page

.supplier-container {
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;
	min-height: calc(100vh - 105px);

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h2 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: $pkBlue;
		&.add-underline {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
		}
	}
	a {
		&:link,
		&:visited,
		&:active {
			color: $lightBlue;
		}
		&:hover,
		&:focus {
			color: $blue;
			text-decoration: none;
		}
	}

	.auction-card {
		border: 1px solid $darkerOutlineGray;
		border-radius: 1rem;
		margin-bottom: 1rem;
		.auction-left {
			padding: 1rem 1rem 1rem 1.5rem;
			img {
				height: 49px;
				width: auto;
			}
			.status-message {
				margin-bottom: 0;
				font-weight: 600;
				&.green {
					color: $green;
				}
				&.orange {
					color: $orange;
				}
				&.red {
					color: $red;
				}
			}
			.time-left {
				margin-bottom: 0;
				&.green {
					color: $green;
				}
				&.orange {
					color: $orange;
				}
				&.red {
					color: $red;
				}
			}
			.auction-button {
				padding: 0.75rem 1rem 0.5rem;
			}
		}
		.auction-right {
			padding-top: 1rem;
			padding-bottom: 0;
			.top-row {
				border-bottom: 1px solid $darkerOutlineGray;
				margin-bottom: 0.5rem;
			}
			.bottom-row {
				background-color: $offRowGray;
				border-radius: 0 0 1rem 1rem;
				.documents-toggle {
					display: block;
					padding-top: 1.25rem;
					padding-bottom: 1rem;
					&:link,
					&:visited,
					&:active,
					&:focus {
						color: $pkBlue;
						font-weight: 600;
					}
					&:hover {
						color: $lightBlue;
					}
					i,
					fa-icon {
						float: right;
						svg {
							transition: 0.3s transform ease-in-out;
						}
					}
					&.collapsed {
						i,
						fa-icon > svg {
							transform: rotate(180deg);
						}
					}
				}
				.documents-collapse {
					width: 100%;
					padding: 0 1rem 1rem 1rem;
					a {
						font-weight: 600;
						i,
						fa-icon {
							font-size: 1.25rem;
							vertical-align: sub;
							margin-right: 0.5rem;
						}
					}
				}
			}
		}
	}

	.bid-page-heading {
		margin-bottom: 0.5rem;
		img {
			height: 35px;
			width: auto;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
	}

	.bids-trends-analysis {
		border: 1px solid $darkGray;
		border-radius: 0.75rem;
		padding: 1rem 1rem 0.5rem 1rem;
		margin-bottom: 1rem;
		.analysis-toggle {
			display: block;
			&:link,
			&:visited,
			&:active,
			&:focus {
				color: $pkBlue;
				font-weight: 600;
			}
			&:hover {
				color: $lightBlue;
			}
			i,
			fa-icon {
				float: right;
				svg {
					transition: 0.3s transform ease-in-out;
				}
			}
			&.collapsed {
				i,
				fa-icon > svg {
					transform: rotate(180deg);
				}
			}
		}
		.analysis-collapse {
			.bid-history {
				border-top: 1px solid $pkBlue;
				padding-top: 1rem;
				overflow-x: auto;
				.table {
					thead {
						tr {
							border-bottom: 1px solid $darkGray;
							th {
								padding: 0.25rem 1rem 0.25rem 0;
								font-weight: 600;
								white-space: nowrap;
							}
						}
					}
					tbody {
						tr {
							th,
							td {
								padding: 0.25rem 1rem 0.25rem 0;
								font-weight: 500;
								white-space: nowrap;
								i,
								fa-icon {
									font-size: 0.4rem;
									vertical-align: middle;
									.color01 {
										color: $green;
									}
									.color02 {
										color: $red;
									}
									.color03 {
										color: $pkBlue;
									}
									.color04 {
										color: $orange;
									}
									.color05 {
										color: $lightBlue;
									}
									.color06 {
										color: $yellow;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.bid-window {
		border: 1px solid $darkGray;
		border-radius: 0.75rem;
		padding: 1rem;
		.bid-window-header {
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 3px;
			margin-bottom: 1rem;
			a {
				font-size: 0.75rem;
				font-weight: 600;
				i,
				fa-icon {
					font-size: 1rem;
					vertical-align: text-bottom;
					margin-right: 0.25rem;
				}
			}
		}
		.form-element-container {
			.unit-of-measure {
				top: 2.35rem;
			}
		}
		.bid-product-row {
			padding-top: 0.5rem;
			&.shaded {
				background-color: $offRowGray !important;
			}
			.showOn1440,
			.showOn1920 {
				display: none;
			}
		}
		.sweet-spot-header {
			border-top: 1px solid $pkBlue;
			margin-top: 2rem;
			padding-top: 1.5rem;
			h4 {
				font-size: 0.85rem;
			}
			a {
				font-size: 0.75rem;
				font-weight: 600;
				i,
				fa-icon {
					font-size: 1rem;
					vertical-align: middle;
					margin-right: 0.25rem;
				}
			}
		}
		.sweet-spot-row {
			padding-top: 0.5rem;
			&.shaded {
				background-color: $offRowGray;
			}
			.delete-row {
				a {
					&:link,
					&:visited,
					&:active {
						color: $red;
					}
					&:hover,
					&:focus {
						color: $darkGray;
					}
					i,
					fa-icon {
						font-size: 1.5rem;
					}
				}
			}
		}
	}

	.saveNotesLink {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 0.75rem;
		i,
		fa-icon {
			font-size: 1.25rem;
			vertical-align: middle;
			margin-right: 0.25rem;
		}
	}

	.chart-filters {
		border: 1px solid $silver;
		border-radius: 0.75rem;
		padding: 1rem 0.5rem;
		margin: 0 0 2rem;
		h3 {
			margin: 0;
			.filters-toggle {
				display: block;
				border-bottom: 1px solid $pkBlue;
				padding-bottom: 0.3rem;
				&:link,
				&:visited,
				&:active,
				&:focus {
					color: $pkBlue;
					font-weight: 600;
				}
				&:hover {
					color: $lightBlue;
				}
				i,
				fa-icon {
					float: right;
					svg {
						transition: 0.3s transform ease-in-out;
					}
				}
				&.collapsed {
					border-bottom: none;
					padding-bottom: 0;
					i,
					fa-icon > svg {
						transform: rotate(180deg);
					}
				}
			}
		}
		.filters-collapse {
			padding-top: 0.75rem;
		}
	}

	.reports-form {
		padding: 0 1rem;
		margin-bottom: 4rem;
		.form-container {
			border-top: 1px solid $pkBlue;
			border-left: 1px solid $outlineGray;
			border-right: 1px solid $outlineGray;
			border-bottom: 1px solid $outlineGray;
			border-radius: 0 0 0.5rem 0.5rem;
			.main-form-row {
				padding: 1.5rem 1rem 2.5rem;
				.reports-switch-label {
					line-height: 1.5rem;
					font-size: 0.85rem;
					font-weight: 500;
				}
				&.no-button {
					padding: 1.5rem 1rem 0.5rem;
				}
				// Makes the label light blue when switch is checked
				.custom-control-input:checked + .reports-switch-label {
					color: $lightBlue;
					font-weight: 600;
				}
			}
			.shifted-button {
				position: relative;
				.btn {
					position: absolute;
					top: -1.15rem;
				}
			}
			.shifted-button-div {
				position: relative;
				.shifted-div {
					position: absolute;
					top: -1.15rem;
				}
			}
		}
	}

	.custom-price-chart {
		border: 1px solid $outlineGray;
		border-radius: 0.75rem;
		padding: 0;
		margin: 0;
		.chart-toggle {
			display: block;
			padding: 1rem 1rem 0.5rem 1rem;
			&:link,
			&:visited,
			&:active,
			&:focus {
				color: $pkBlue;
				font-weight: 600;
			}
			&:hover {
				color: $lightBlue;
			}
			i,
			fa-icon {
				float: right;
				svg {
					transition: 0.3s transform ease-in-out;
				}
			}
			&.collapsed {
				i,
				fa-icon > svg {
					transform: rotate(180deg);
				}
			}
		}
	}
	// Control Panel above table
	.table-controls {
		background-color: $offRowGray;
		border-bottom: 1px solid $darkGray;
		.table-control-link {
			padding: 0;
			a {
				display: block;
				padding: 0.75rem 15px;
				font-size: 0.85rem;
				font-weight: 600;
				&:link,
				&:visited,
				&:active {
					i,
					fa-icon {
						color: $blueGray;
						font-size: 1rem;
						vertical-align: baseline;
					}
				}
				&:hover,
				&:focus {
					color: $white;
					background-color: $iconLinkBlue;
					i,
					fa-icon {
						color: $white;
					}
				}
				span {
					display: none;
				}
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	.supplier-container {
		.auction-card {
			.auction-right {
				.bottom-row {
					border-radius: 0 0 1rem 0;
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.supplier-container {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.25rem;
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.supplier-container {
		// Control Panel above table
		.table-controls {
			.table-control-link {
				a {
					span {
						display: inline;
					}
				}
			}
		}
	}
}

// 	Styles for the larger desktop monitors.
@media (min-width: 1440px) {
	.supplier-container {
		.bid-window {
			.bid-product-row {
				.showOn1440 {
					display: block;
				}
			}
		}
	}
}

// 	Styles for the HD monitors.
@media (min-width: 1920px) {
	.supplier-container {
		.bid-window {
			.bid-product-row {
				.showOn1920 {
					display: block;
				}
			}
		}
	}
}
