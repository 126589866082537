// 	Styles specific for the Home Page
.home-page-container {
	overflow: auto;
	font-family: $mainFont;
	font-size: 0.9rem;
	color: $darkGray;
	padding: 2rem;

	h2 {
		&.with-underline {
			font-size: 1rem;
			font-weight: 600;
			color: $pkBlue;
			border-bottom: 1px solid $pkBlue;
			padding-bottom: 0.25rem;
		}
	}

	.dashboard-grid {
		padding: 0 1.75rem 1rem;
		& > .row {
			height: 100%;
			min-height: 100%;
		}
		.dashboard-container {
			border: 1px solid $outlineGray;
			border-radius: 0.5rem;
			padding-top: 1rem;
			padding-bottom: 0.5rem;
			h2 {
				color: $pkBlue;
				font-size: 1.15rem;
				font-weight: 600;
				margin-bottom: 1rem;
			}
			.big-number-label {
				font-size: 0.75rem;
				font-weight: 600;
				margin-bottom: 0;
			}
			.big-number {
				font-size: 1.75rem;
				color: $pkBlue;
				font-weight: 700;
				line-height: 1.75rem;
				sup {
					font-size: 1rem;
				}
			}
			.table-container {
				overflow-x: auto;
				display: inline;
				margin: 0 1rem 1rem;
				padding: 0;
			}
		}
	}

	// Hot Links
	.hot-links {
		font-size: 14px;
		padding-top: 0;
		padding-bottom: 1rem;
		font-weight: 600;
		i,
		fa-icon {
			font-size: 1.5rem;
			color: $iconLinkBlue;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
		a:hover {
			text-decoration: underline;
		}
	}

	// Message Tabs
	.message-tabs-row {
		.tab-container {
			width: 100% !important;
		}
		.tab-content {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			.tab-pane {
				padding: 1rem;
				background-color: $offRowGray;
				border: 1px solid $pkBlue;
				border-radius: 0 0 1rem 1rem;
			}
		}
		&.message-tab-stretch-height {
			.tab-content {
				min-height: 300px;
			}
		}
		.nav {
			border-bottom: none;
			z-index: $home-page-nav-z;
			.nav-item {
				z-index: $home-page-nav-item-z;
				text-align: left;
				.nav-link {
					padding: 0.75rem 0 0 1.7rem;
					&:link,
					&:visited,
					&:active,
					&:focus {
						border-top: 1px solid $placeholderGray;
						border-left: 1px solid $placeholderGray;
						border-right: 1px solid $placeholderGray;
						border-bottom: 1px solid $placeholderGray;
						border-radius: 1rem;
						background-color: $white;
					}
					&:hover {
						border-top: 1px solid $pkBlue;
						border-left: 1px solid $pkBlue;
						border-right: 1px solid $pkBlue;
						border-bottom: 1px solid $pkBlue;
						background-color: $offRowGray;
					}
					&.active {
						background-color: $offRowGray;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
						border-bottom: none;
					}
				}
				.message-tab-header {
					h3 {
						border-bottom: none;
						font-size: 0.65rem;
					}
					.message-tab-toggle {
						color: $pkBlue;
						i,
						fa-icon {
							-webkit-transition: 0.3s transform ease-in-out;
							transition: 0.3s transform ease-in-out;
						}
					}
				}
				.message-tab-numbers {
					font-size: 1.75rem;
					font-weight: 700;
					i,
					fa-icon {
						display: none;
					}
					.smaller-number {
						font-size: 0.9rem;
						text-transform: uppercase;
					}
					&.tasks-style {
						color: $orange;
					}
					&.notifications-style {
						color: $lightBlue;
					}
					&.market-updates-style {
						color: $green;
					}
				}
				&.active {
					background-color: $offRowGray;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom: none;
					.message-tab-header {
						.message-tab-toggle {
							i,
							fa-icon {
								-webkit-transform: rotate(180deg);
								transform: rotate(180deg);
							}
						}
					}
				}
			}
			&.nav-tabs {
				border-bottom: none;
			}
		}
		.tab-pane {
			padding: 1rem;
			background-color: $offRowGray;
			border: 1px solid $pkBlue;
			border-radius: 0 0 1rem 1rem;
			width: 100%;
			.top-controls {
				.top-controls-link {
					font-size: 0.75rem;
					a {
						i,
						fa-icon {
							font-size: 1.25rem;
							vertical-align: sub;
							margin-right: 0.5rem;
						}
					}
				}
			}
			accordion {
				.card {
					font-size: 0.75rem;
					border: none;
					.card-header {
						background: none;
						border: none;
						padding: 0px 15px 1px;
						a {
							border-top: 1px solid $pkBlue;
							border-bottom: none;
							border-left: 1px solid $pkBlue;
							border-right: 1px solid $pkBlue;
							background-color: $white;
							padding: 0.5rem;
							&:link,
							&:visited,
							&:active,
							&:focus {
								.message-designation {
									min-width: 2rem;
								}
								.message-date {
									color: $darkGray;
								}
								.toggle-chevron {
									font-size: 1rem;
									i,
									fa-icon {
										-webkit-transition: 0.3s transform ease-in-out;
										transition: 0.3s transform ease-in-out;
									}
								}
								&.collapsed {
									border-bottom: 1px solid $outlineGray;
									border-top: 1px solid $outlineGray;
									border-left: 1px solid $outlineGray;
									border-right: 1px solid $outlineGray;
									.toggle-chevron {
										i,
										fa-icon {
											-webkit-transform: rotate(180deg);
											transform: rotate(180deg);
										}
									}
								}
							}
						}
					}
					&.new {
						a {
							&:link,
							&:visited,
							&:active,
							&:focus {
								.message-designation {
									color: $pkBlue;
									font-size: 1rem;
								}
								.message-title {
									font-weight: 600;
								}
							}
						}
					}
					&.completed {
						a {
							&:link,
							&:visited,
							&:active,
							&:focus {
								.message-designation {
									color: $green;
									font-size: 0.75rem;
								}
								.message-title {
									color: $darkGray;
								}
							}
						}
					}
					.collapse,
					.collapsing {
						border-bottom: 1px solid $pkBlue;
						border-left: 1px solid $pkBlue;
						border-right: 1px solid $pkBlue;
						.card-body {
							.card-body-div {
								padding: 0.5rem 1rem;
							}
							.task-controls {
								font-weight: 600;
								background-color: $offRowGray;
								margin: 0.5rem 0.15rem;
								padding-top: 0.5rem;
								padding-bottom: 0.5rem;
								i,
								fa-icon {
									font-size: 1.25rem;
									vertical-align: sub;
									margin-right: 0.5rem;
								}
							}
						}
					}
				}
			}
			.market-update-list {
				list-style-type: none;
				padding: 0 0.25rem;
				margin-bottom: 0;
				.market-update-item {
					width: 100%;
					font-size: 0.75rem;
					font-weight: 600;
					a {
						padding: 0.5rem, 1rem;
						display: block;
						&:link,
						&:visited,
						&:active {
							border: 1px solid $outlineGray;
							background-color: $white;
							padding: 0.5rem 0.5rem 0.5rem 1.5rem;
						}
						&:hover,
						&:focus {
							border: 1px solid $pkBlue;
						}
					}
				}
			}
		}
	}

	// Contracts that need your attention
	.contracts-attention-header {
		h2 {
			border-bottom: none;
			a {
				&:link,
				&:visited,
				&:active,
				&:focus {
					color: $pkBlue;
					i,
					fa-icon {
						transition: 0.3s transform ease-in-out;
						color: $iconLinkBlue;
					}
					.details-text {
						display: inline-block;
						font-size: 0.75rem;
						color: $lightBlue;
						margin-right: 0.5rem;
						padding-top: 0.15rem;
						&::after {
							content: 'Hide';
						}
					}
				}
				&:hover {
					color: $pkBlue;
					i,
					fa-icon {
						color: $pkBlue;
					}
					.details-text {
						color: $pkBlue;
					}
				}
				&.collapsed {
					i,
					fa-icon {
						transform: rotate(180deg);
					}
					.details-text {
						&::after {
							content: 'Details';
						}
					}
				}
			}
		}
	}
	.contract-needing-attention {
		position: relative;
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		// Status Ribbon
		.ribbon {
			width: 100px;
			height: 100px;
			overflow: hidden;
			position: absolute;
			z-index: 1;
			&::before,
			&::after {
				position: absolute;
				z-index: -1;
				content: '';
				display: block;
			}
			span {
				position: absolute;
				display: block;
				width: 225px;
				padding: 10px 0;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
				color: $white;
				font-weight: 700;
				font-size: 0.75rem;
				text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
				text-transform: uppercase;
				text-align: center;
			}
			&.act-now {
				&::before,
				&::after {
					border: 5px solid #006a00;
				}
				span {
					background-color: $green;
				}
			}
			&.dropped {
				&::before,
				&::after {
					border: 5px solid #bc0000;
				}
				span {
					background-color: $red;
				}
			}
			&.rejected {
				&::before,
				&::after {
					border: 5px solid #557384;
				}
				span {
					background-color: $blueGray;
				}
			}
		}
		.ribbon-top-left {
			top: -10px;
			left: -10px;
			&::before {
				border-top-color: transparent;
				border-left-color: transparent;
				top: 0;
				right: 0;
			}
			&::after {
				border-top-color: transparent;
				border-left-color: transparent;
				bottom: 0;
				left: 0;
			}
			span {
				right: -51px;
				top: 20px;
				transform: rotate(-45deg);
				line-height: 1rem;
			}
		}
		.energy-icon {
			text-align: right;
			img {
				height: 3.75rem;
				width: auto;
			}
		}
		.main-info {
			font-weight: 600;
			padding-left: 4.5rem;
		}
		.issue-info {
			background-color: $offRowGray;
			border-radius: 0 0.5rem 0.5rem 0;
			padding-top: 0.75rem;
			padding-bottom: 1.25rem;
			min-height: 7.5rem;
		}
		&.dismissed-contract {
			background-color: $offWhite;
			border-color: $gray;
			min-height: 7.5rem;
			.dismissed-message {
				text-align: center;
			}
		}
	}

	.contracts-table-header,
	.dashboard-component {
		.compact-select-label {
			font-weight: 600;
		}
		a {
			font-size: 1rem;
			font-weight: 600;
			&:focus {
				color: $lightBlue;
			}
			i,
			fa-icon {
				font-size: 1rem;
				vertical-align: middle;
				margin-right: 0rem;
			}
		}
		.dropdown {
			.dropdown-menu {
				.dropdown-subhead {
					font-size: 1rem;
					border-bottom: 1px solid $pkBlue;
					font-weight: 600;
					margin-bottom: 0.5rem;
					padding-left: 0;
				}
				.default-select {
					display: block;
					padding-top: 2px;
					padding-left: 2px;
					padding-right: 10px;
					&:link,
					&:visited,
					&:active {
						color: $halfBlue;
						&.default-item {
							color: $pkBlue;
						}
					}
					&:hover,
					&:focus {
						color: $tooltipBlue;
						&.default-item {
							color: $pkBlue;
						}
					}
				}
				.dropdown-item {
					font-size: 0.8rem;
					padding: 0.25rem 0.5rem 0.25rem 0.5rem;
				}
			}
		}
	}

	.contracts-table-container {
		overflow-x: scroll;
		display: inline;
		margin: 0 1rem 1rem;
		padding: 0;
		table {
			font-size: 0.75rem;
			border-right: 1px solid $darkGray;
			tr {
				th {
					border-top: 1px solid $darkGray;
					border-left: 1px solid $darkGray;
					border-bottom: 2px solid $darkGray;
				}
				td {
					border-left: 1px solid $darkGray;
					border-bottom: 1px solid $darkGray;
				}
				&.highlighted {
					background-color: $blueGray;
					color: $white;
					th,
					td {
						a {
							color: $white;
						}
					}
				}
			}
		}
		.table-striped tbody {
			tr:nth-of-type(odd) {
				background-color: $offRowGray;
			}
			tr.highlighted:nth-of-type(odd) {
				background-color: $blueGray;
			}
		}
	}

	.configure-table-modal {
		.left-column {
			.main-column-header {
				color: $black;
				font-weight: 600;
				font-size: 1em;
				border-bottom: 2px solid $pkBlue;
				& > div {
					padding-top: 0.25rem;
					padding-bottom: 0.25rem;
				}
			}
			.column-choice {
				color: $black;
				font-weight: 500;
				font-size: 0.75em;
				border-bottom: 1px solid $pkBlue;
				& > div {
					padding-top: 0.75rem;
					padding-bottom: 0.25rem;
				}
				.show-hide-switch {
					label {
						&::before {
							top: 0;
						}
						&::after {
							top: calc(0.05em + 2px);
						}
						span {
							font-size: 0.675rem;
							&::after {
								content: 'Hidden';
							}
						}
					}
					.custom-control-input:checked ~ .custom-control-label span::after {
						content: 'Shown';
					}
				}
			}
		}
		.right-column {
			.main-column-header {
				color: $black;
				font-weight: 600;
				font-size: 1em;
				& > div {
					padding-top: 0.25rem;
					padding-bottom: 0.25rem;
				}
			}
			.column-sort-headers {
				font-size: 0.75rem;
				font-weight: 700;
				border-bottom: 2px solid $pkBlue;
				margin-top: 1rem;
				padding-bottom: 0.25rem;
			}
			.column-sort-row {
				font-size: 0.75rem;
				margin-top: 1rem;
				.form-element-container {
					padding-bottom: 0;
				}
				i,
				fa-icon {
					font-size: 1.25rem;
				}
			}
		}
	}

	.export-modal {
		a {
			&:link,
			&:visited,
			&:active {
				&.export-link {
					font-weight: 600;
					i,
					fa-icon {
						font-size: 2rem;
						margin-right: 0.5rem;
						vertical-align: sub;
					}
				}
			}
			&:hover,
			&:focus {
				&.export-link {
					text-decoration: underline;
					i,
					fa-icon {
						text-decoration: none;
					}
				}
			}
		}
	}

	// Fixes for the lower scrollbar on the Contracts table
	.dx-scrollable-customizable-scrollbars {
		& .dx-scrollable-container {
			&::-webkit-scrollbar:horizontal {
				background-color: #f1f1f1;
				height: 17px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #c1c1c1;
				border-top: 2px solid #f1f1f1;
				border-bottom: 2px solid #f1f1f1;
			}
			&::-webkit-scrollbar-track {
				background-color: #f1f1f1;
			}
			&::-webkit-scrollbar-button:start:decrement {
				background-color: #f1f1f1;
				background-size: 4px 6px;
			}
			&::-webkit-scrollbar-button:end:increment {
				background-color: #f1f1f1;
				background-size: 4px 6px;
			}
			&::-webkit-scrollbar-track-piece {
				background-color: #f1f1f1;
			}
		}
	}

	.chart-cell {
		.deposit-required {
			font-weight: 700;
			color: $red !important;
		}
	}

	// Cards for Auctions Ending
	.auction-card {
		background-color: $offRowGray;
		border: 1px solid $outlineGray;
		border-radius: 0.5rem;
		padding: 1rem;
		a {
			&:link,
			&:visited,
			&:active {
				i,
				fa-icon {
					font-size: 1.25rem;
					color: $blueGray;
				}
			}
			&:hover,
			&:focus {
				i,
				fa-icon {
					color: $blue;
				}
			}
		}
	}
}

// 	Styles for the small breakpoint.
@media (min-width: 576px) {
	.home-page-container {
		// Contracts that need your attention
		.contract-needing-attention {
			.energy-icon {
				padding-left: 4rem;
			}
			.main-info {
				padding-left: 15px;
			}
		}
		// Message Tabs
		.message-tabs-row {
			.nav {
				.nav-item {
					.nav-link {
						padding: 1rem 2rem 0.75rem;
						.message-tab-header {
							border-bottom: 1px solid $pkBlue;
							h3 {
								font-size: 0.75rem;
							}
						}
						&.active {
							height: 8rem !important;
						}
					}
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.home-page-container {
		// Contracts that need your attention
		.contract-needing-attention {
			.energy-icon {
				padding-left: 4.5rem;
			}
		}
		.message-tabs-row {
			.nav {
				.nav-item {
					.nav-link {
						.message-tab-header {
							.message-tab-header {
								h3 {
									font-size: 1rem;
								}
							}
						}
						.message-tab-numbers {
							font-size: 1.75rem;
							padding-top: 0.5rem;
							i,
							fa-icon {
								display: inline;
								margin-right: 0.5rem;
							}
							.smaller-number {
								font-size: 0.9rem;
							}
						}
					}
				}
			}
			.tab-pane {
				accordion {
					.card {
						.collapse,
						.collapsing {
							.card-body {
								.card-body-div {
									padding: 0.5rem 0.5rem 0.5rem 2.5rem;
								}
								.task-controls {
									margin: 1rem;
									i,
									fa-icon {
										font-size: 1.5rem;
										vertical-align: sub;
										margin-right: 0.5rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.admin-buckets {
		font-size: 1rem;
		.main-bucket-link {
			padding: 0.75rem;
			border-radius: 0.5rem;
			display: block;
			transition: 0.5s;
			&:link,
			&:visited,
			&:active {
				background-color: $white;
				border: 1px solid $darkGray;
				color: $black;
				.bucket-icon,
				fa-icon {
					font-size: 4rem;
					color: $lightBlue;
					transition: 0.5s;
				}
				h2 {
					font-size: 1.2rem;
					color: $black;
				}
			}
			&:hover,
			&:focus {
				background-color: $halfBlue;
				border: 1px solid $pkBlue;
				color: $black;
				text-decoration: none;
				.bucket-icon {
					color: $pkBlue;
				}
				.btn-light {
					color: $white;
					background-color: $pkBlue;
				}
			}
		}
	}
}

// 	Styles for the medium breakpoint.
@media (min-width: 768px) {
	.home-page-container {
		h2 {
			&.with-underline {
				font-size: 1.25rem;
			}
		}
	}
}

// 	Styles for the large breakpoint.
@media (min-width: 992px) {
	.home-page-container {
		// Contracts that need your attention
		.contract-needing-attention {
			.energy-icon {
				padding-left: 3.5rem;
			}
		}
	}
}

// 	Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
	.home-page-container {
		// Contracts that need your attention
		.contract-needing-attention {
			.energy-icon {
				padding-left: 4rem;
			}
		}
	}
}

// 	Styles for the XXL breakpoint.
@media (min-width: 1440px) {
}

// 	Styles for the XXXL breakpoint.
@media (min-width: 1920px) {
}
