// 	Styles specific for the Market Update Page

.market-update-container {
	font-size: 0.75rem;
	min-height: calc(100% - 61px);
	padding-top: 12em !important;
	.energy-icon {
		height: 2.5rem;
		width: auto;
		margin-right: 0.5rem;
	}
	.up-down-arrow {
		height: 0.85rem;
		width: auto;
		vertical-align: middle;
	}
	.commodity-icon-table {
		height: 1.5rem;
		width: auto;
	}
	.table {
		thead {
			tr {
				background-color: $pkBlue;
				color: $white;
				th {
					border-top: none;
				}
			}
		}
		tbody {
			tr {
				td {
					border-top: none;
					&.best-matrix-rate {
						color: $green;
						font-weight: 600;
					}
				}
			}
		}
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: $white;
	}
	.table-striped tbody tr:nth-of-type(even) {
		background-color: $lightGray;
	}
}
